import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { DataTable, CustomInputLabel } from "../..";
import RecordEditor from "./RecordEditor";
import AddRecord from "./AddRecord";

const toKeyValuePair = (keys: string, values: [string]) => {
  return keys
    .split("|")
    .reduce((acc, key, index) => ({ ...acc, [key]: values[index] }), {});
};

const TabularFieldRenderer: React.FC<any> = ({
  label,
  columnValues,
  columnHeaders,
  onChange,
}: any) => {
  const formattedColumnHeaders = columnHeaders
    .split("|")
    .map((header: string) => ({ id: header, label: header }));

  const formattedColumnValues = columnValues.map(
    (record: any, index: number) => ({
      ...toKeyValuePair(columnHeaders, record),
      _meta: { rowIndex: index },
    })
  );

  return (
    <>
      <Grid container style={{ padding: "16px 20px" }}>
        <CustomInputLabel style={{ margin: "10px" }}>{label}</CustomInputLabel>
        <DataTable
          columns={[
            ...formattedColumnHeaders,
            {
              id: "action",
              template: ({ record }: any) => (
                <div
                  style={{
                    display: "flex",
                    width: "6em",
                    justifyContent: "space-between",
                  }}
                >
                  <RecordEditor
                    label={label}
                    record={record}
                    onRecordEdit={(changedRecord: any) => {
                      const updatedColumnValues = columnValues.map(
                        (cv: any, index: number) => {
                          if (index === changedRecord._meta.rowIndex) {
                            return Object.keys(changedRecord)
                              .filter((k) => k !== "_meta")
                              .map((k: string) => changedRecord[k]);
                          }
                          return cv;
                        }
                      );
                      onChange(updatedColumnValues);
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      const updatedColumnValues = columnValues.filter(
                        (_: any, index: number) => {
                          return index !== record._meta.rowIndex;
                        }
                      );
                      onChange(updatedColumnValues);
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </div>
              ),
            },
          ]}
          values={formattedColumnValues}
          refreshGrid={() => true}
          noWrapper
        />
        <AddRecord
          label={label}
          columnHeaders={formattedColumnHeaders}
          onAdd={(newRecord: any) => {
            const updatedColumnValues = [
              ...columnValues,
              newRecord.map(({ value }: any) => value),
            ];
            onChange(updatedColumnValues);
          }}
        />
      </Grid>
    </>
  );
};

export default TabularFieldRenderer;
