import React from "react";
import { useHistory, useParams } from "react-router";
import useApi from "../../../../hooks/useApi";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import { LoadingIndicator } from "../../../UiComponents";
import LegacyEditDevice from "./LegacyEditDevice";

/**
 EditDevice still contains the same kind of spaghetti code that we had in ShowDevices component.
 But we are not refactoring it. To make it work, we need to load the data externally and supply
 to it. So the adapter  does just that.
 */

const LegacyEditDeviceAdapter: React.FC<any> = () => {
  const { deviceDlmId }: any = useParams();
  const history = useHistory();
  const { selectedApplication } = useApplicationContext();
  const { data, status } = useApi(
    `/applications/${selectedApplication.application_id}/devices/${deviceDlmId}`,
    { method: "GET" }
  );

  if (status.pending || !data) {
    return <LoadingIndicator />;
  }

  return (
    <LegacyEditDevice
      editItem={data.device}
      editDlmId={deviceDlmId}
      editApp={selectedApplication.application_id}
      onEditDevice={() => history.push("/")}
    />
  );
};

export default LegacyEditDeviceAdapter;
