import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { SnackbarProvider, useSnackbar } from "notistack";
import { API } from "../../api/property";
import ErrorDialog from "../../utils/ErrorDialog";
import callXhrRequest from '../../utils/xhrRequestHandler';
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import {Backdrop,CircularProgress } from "@material-ui/core"

const textFieldBorder ="1px solid #464659"

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      fontSize: "21px",
      color: "#FFFFFF",
      lineHeight: "25px",
    },
    form: {
      width: "100%", 
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      width: "100%",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${textFieldBorder}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcOuterPadding": {
        padding: "10px 20px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        margin: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    bgColor: {
      background: "#2B2B36",
      margin: "1px 0",
      padding: "7px 0",
    },
    marginLeft15: {
      backgroundColor: "#2B2B36",
      borderBottom: `${textFieldBorder}`,
      color: "#FFFFFF",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    helperText: {
      color: "#d32f2f !important",
      position: "absolute",
      right: 0,
    },
    paddingTop: {
      padding: "1.5rem 0 !important",
    },
    isExpand: {
      position: "relative",
      top: "1.25rem",
    },
    dcFieldValue: {
      margin: "10px !important",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });


export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface ComponentProps {
  onCancelUser: any;
}

const AddNewRoleWrapper: React.FC<ComponentProps> = (props: ComponentProps) => {
  const classes = useStyles();
  const { user } = useStore();

  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const { enqueueSnackbar } = useSnackbar();
  const [permission, setPermission] = useState([] as any);
  const [backDrop, setbackDrop] = useState(false);
  const [role, setRoleName] = useState("");
  const [description, setRoleDesc] = useState("");
  const [reload, setReload] = React.useState(false);
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [userInputAppls, setUserInputAppls] = useState([] as any);
  const [app, setApp] = useState(
    userInputAppls ? (userInputAppls.length > 0 && userInputAppls[0].application_name) : ""
  );
  const [validateRole, setValidateRole] = useState([]);


  const updateError = () => {
    setError(false);
    setErrMsg("");
  };
  const onChangeApp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApp(event.target.value);
  };
  const [checkboxSelect, setCheckboxSelect] = useState(true);
  const onCheckbox = (event: any, checked: boolean) => {
    const [name, perm] = event.target.name.split("#");
    const newPermission: any = permission.map((e: any) => {
      if (e.component_code === name) {
        let selected = [...e.selected] || [];
        if (checked) {
          if (perm === "read") {
            selected.push("read");
          } else if (perm === "write") {
            selected.push("write");
          } else if (perm === "delete") {
            selected.push("delete");
          }
        } else {
          selected = selected.filter((e: any) => e !== perm);
        }
        selected.length > 0 ? setCheckboxSelect(false) : setCheckboxSelect(true)
        return { ...e, selected };
      }
      return e;
    });
    setPermission(newPermission);
  };
  const backDropClose = () => {
    setbackDrop(false);
  };
  const saveUserAction = () => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const ROLES_API = `${API["GETALLAPPLS"]}/${app}/roles`;
    const permissionMapping = permission.map((item: any) => {
      const permissions: any = [];
      if (item.selected.includes("read")) {
        permissions.push("read");
      }
      if (item.selected.includes("write")) {
        permissions.push("write");
      }
      if (item.selected.includes("delete")) {
        permissions.push("delete");
      }
      return {
        component_code: item.component_code,
        component_desc: item.component_desc,
        permission: permissions.join("|"),
      };
    });
    setbackDrop(true)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
      body: JSON.stringify({
        role: {
          role_name: role,
          role_desc: description,
          permission_mapping: permissionMapping,
        },
        pm_s_token: pmST,
        pm_s_id: pmSId,
      }),
    };
    fetch(ROLES_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
       
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function(data){
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              saveUserAction()
            }).catch(function(error){
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Role Created", { variant: "success" });
          const menuClose = permission.map((m: any) => false);
          setPermission(menuClose);
          setbackDrop(false);
         setTimeout(()=>{cancelUserAction()},500) 
        } 
        else {
          setError(true);
          setErrMsg("Error occured. Please try again after sometime.");
          setbackDrop(false);
        }
      })
    })
      .catch(function (error) {
        console.log(error)
      });
  };
  const validation = () => {
    const regexCheck = /^[0-9a-zA-Z_-]+$/;
    const regexCheckDesc = /^[0-9a-zA-Z_-\s]+$/;
    const uniqueRole = validateRole.some((e: any) => {
      if (e.role_name === role) {
        return true
      } else {
        return false
      }
    })
    if (!role) {
      enqueueSnackbar("Please enter role name.", { variant: "error" });
    } else if (!(regexCheck.test(role) && role.length < 50)) {
      enqueueSnackbar('Invalid role name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)', { variant: "error" });
    } else if (uniqueRole) {
      enqueueSnackbar("Role name is already there. Please enter another role name.", { variant: "error" });
    } else if (!app) {
      enqueueSnackbar("Please select application.", { variant: "error" });
    } else if (!description) {
      enqueueSnackbar("Please enter description of role.", { variant: "error" });
    } else if (!(regexCheckDesc.test(description) && description.length < 50)) {
      enqueueSnackbar('Invalid description of role (only 0-9,A-Z,a-z,_,- and space allowed upto 50 characters).', { variant: "error" });
    } else if (checkboxSelect) {
      enqueueSnackbar("Please select at least one checkbox.", { variant: "error" });
    } else {
      saveUserAction();
    }
  }
  const cancelUserAction = () => {

    permission.forEach((e: any) => {
      e.selected.length = 0
    })
    setRoleName("");
    setRoleDesc("");
    setApp("");
    setPermission(permission);
    // window.location.reload();

    props.onCancelUser();
  };
  useEffect(() => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sObj {
      pm_s_id: string;
    }
    interface tObj {
      pm_s_token: string;
    }
    let pmSId: sObj = {
      pm_s_id: "",
    };
    let pmST: tObj = {
      pm_s_token: "",
    };
    pmSId =
      sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
    pmST =
      sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
    const APPS_API = API["ROLES_APPLS_INPUT"];
    const ALL_ROLES = API["GETALLROLES"];
    Promise.all([
      fetch(`${APPS_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
      fetch(`${ALL_ROLES}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      })
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        if ((response && response[0].status === 401) && (response && arrayOfErrorsRefresh.includes(response[0].message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest().then(function(data){
                sessionStorage.setItem(tokenKey, data);
                setReload(!reload)
            }).catch(function(error){
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response[0].status === 401) && (response && arrayOfErrorsLogout.includes(response[0].message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (
          response &&
          response[0].status === 200 &&
          typeof response[0].roles_input[0].applications !== "undefined"
        ) {
          setUserInputAppls(response && response[0].roles_input[0].applications);
          const newPermission =
            response[0].roles_input[0].portal_components &&
            response[0].roles_input[0].portal_components.map((e: any) => {
              const perm = e.possible_permissions.split("|");
              return {
                component_code: e.component_code,
                component_desc: e.component_desc,
                read: perm.includes("read"),
                write: perm.includes("write"),
                delete: perm.includes("delete"),
                selected: [],
              };
            });
          setPermission(newPermission);
        }
        if (
          response &&
          response[1].status === 200 &&
          typeof response[1].roles !== "undefined"
        ) {
          setValidateRole(response && response[1].roles);
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }, [ API_KEY, reload]);
  return (
    <div className={classes.root}>
      <p style={{ color: "#FFFFFF", fontSize: "12px" }}>All fields marked * are mandatory.</p>
      <form className={classes.form} noValidate method="post">
        <Grid container>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="rolename"
              label="Role Name *"
              type="text"
              id="rolename"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setRoleName(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={role}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceRight}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="selectApplication"
              >
                Application *
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                select
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={app}
                id="selectApplication"
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
                onChange={onChangeApp}
              >
                {userInputAppls ? userInputAppls.map((option: any) => (
                  <option
                    className={classes.options}
                    key={option.application_name}
                    value={option.application_id}
                  >
                    {option.application_name}
                  </option>
                )) : <option></option>}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="desc"
              label="Description *"
              type="text"
              id="desc"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setRoleDesc(e.target.value),
              }}
              style={{ height: "83px" }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={description}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.paddingTop}>
          <Grid
            container
            spacing={0}
            className="dcOuterPadding"
            style={{ paddingBottom: "1rem" }}
          >
            <Grid item xs={6}>
              <Typography className={classes.colorWhite}>
                Permissions *
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.colorWhite}>Read</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.colorWhite}>Write</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.colorWhite}>Create/Delete</Typography>
            </Grid>
          </Grid>
          {Array.isArray(permission) &&
            permission.map((role: any, index: number) => {
              return (
                <>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={0}
                      className={classes.marginLeft15}
                    >
                      <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                          <Typography className={classes.dcFieldValue}>
                            {role.component_desc}
                          </Typography>
                        </Grid>
                        {["read", "write", "delete"].map((i: any) => {
                          return (
                            <Grid item xs={2}>
                              {role[i] && (
                                <Checkbox
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                  className={classes.bgColorBlue}
                                  onChange={onCheckbox}
                                  name={`${role.component_code}#${i}`}
                                />
                              )}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </Grid>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={cancelUserAction}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={validation}
          >
            Save Role
          </Button>
        </div>
        <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>

      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </div>
  );
};

interface ChildComponentProps {
  onCancelUser: any;
}

export default function AddNewRole({ onCancelUser }: ChildComponentProps) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <AddNewRoleWrapper onCancelUser={onCancelUser} />
    </SnackbarProvider>
  );
}
