import React from "react";

import {
  PhotoLibrary as PhotoLibraryIcon,
  GpsFixed as GpsIcon,
  Comment as CommentIcon,
} from "@material-ui/icons";

import Comments from "../../../../UiComponents/Comments";
import { PAGES } from "../../../../../utils/constant";
import AddGps from "./AddGps";
import AddRemoveImageWrapper from "./AddRemoveImageWrapper";

interface IMenuItem {
  id: string;
  icon: any;
  label: string;
  component: React.ReactElement;
}

const menuItems: IMenuItem[] = [
  {
    id: "addGps",
    icon: GpsIcon,
    label: "GPS Location",
    component: <AddGps />,
  },
  {
    //TODO: Replace with AddRemoveImage from UIComponents
    id: "addRemoveImages",
    icon: PhotoLibraryIcon,
    label: "Add/Remove Images",
    component: <AddRemoveImageWrapper />,
  },
  {
    id: "comments",
    icon: CommentIcon,
    label: "Comments",
    component: <Comments requestPage={PAGES.DEVICE_INSTALLATION} />,
  },
];

export default menuItems;
