import React, { useEffect, useState } from "react";
import Breadcrum from "../Breadcrum";
import AddNewUser from "./AddNewUser";
import EditUser from "./EditUser";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import exportLogo from "../../assets/Export.png";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";
import deleteLogo from "../../assets/Delete.png";
import { API } from "../../api/property";
import { SnackbarProvider, useSnackbar } from "notistack";
import Pagination from "material-ui-flat-pagination";
import "./Pagination.css";
import { exportToExcel } from "../../utils/helper";
import ErrorDialog from "../../utils/ErrorDialog";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import callXhrRequest from '../../utils/xhrRequestHandler';
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';

const textFieldBorder = "1px solid #464659";
const displayVar = 'inline-block'

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

const StyledTableSort = withStyles({
  root: {
    color: '#ffffff',
  },
  icon: {
    color: '#ffffff !important',
    fontSize: "26px"
  },
})(TableSortLabel)


const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#2B2B36",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    formControl: {
      margin: "0 25px",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
      margin: "0px 20px 0px 20px",
      borderBottom: "solid thin #33333F",
    },
    gridRootApp: {
      flexGrow: 1,
      padding: "0",
      margin: "0 20px 20px",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      color: "#FFFFFF",
      lineHeight: "19px",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      "&:hover": {
        color: "#0089FF !important",
      },
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",

    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "2px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "10px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "10px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "10px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    tblpaginate: {
      color: "#fff",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    tblfooter: {
      position: "absolute",
      display: `${displayVar}`
    },
    tblfooter1: {
      position: "sticky",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
    },
    tblfooter2: {
      position: "sticky",
      right: "0",
      padding: "20px 25px",
      display: "flex",
      marginLeft: "87.4rem",
      marginTop: "-58px"
    },
    tblpaginatenumber: {
      color: "#fff",
      marginRight: "13px"
    },
    paginateUser: {
      color: "#fff",
    },
    btnTableCell: {
      color: "#FFFFFF",
      padding: "8px 0",
      fontWeight: "bold",
      borderBottom: "none",
      backgroundColor: "#464659",
      textTransform: "capitalize",
      fontSize: "0.875rem",
    },
    lblFilterBox: {
      color: "#FFFFFF",
    },
    popoverInputSearch: {
      margin: "15px",
      border: "1px solid white",
    },
    space2Top: {
      marginTop: "5px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "7px",
      paddingBottom: "7px",
    },
    btnCancel: {
      color: "gray",
      fontSize: "15px",
      textTransform: "capitalize",
    },
    btnAddNUser: {
      color: "#0089ff",
      fontSize: "15px",
      textTransform: "capitalize",
    },
    formControlOvrflw: {
      overflowY: "scroll",
      margin: "0 25px",
      width: "90%",
      maxHeight: "250px",
    },
    paginateView: {
      color: "#fff",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      color: "#FFFFFF",
    },
    iconButton: {
      padding: 10,
      color: "#FFFFFF",
    },
    permissionError: {
      display: "flex",
      flexDirection:'column',
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      height: "80vh",
      width: "100%"
    }
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);


const getFilteredItem = (items: any, search: string) => {
  const _search = search.toLowerCase();

  return items.filter((item: any) => {
    if (item.email_id && item.email_id.toLowerCase().includes(_search)) {
      return true
    }
    if (item.valid_to && item.valid_to.toLowerCase().includes(_search)) {
      return true
    }
    if (item.last_login && item.last_login.toLowerCase().includes(_search)) {
      return true
    }
    if (item.status && item.status.toLowerCase().includes(_search)) {
      return true
    }
    return false
  })
}

const getSortedItem = (sortBy: string, sortOrder: string, selectedApplication: any) => {
  if(!selectedApplication) {
    return [];
  }
  const itemsToSort = JSON.parse(JSON.stringify(selectedApplication));
  const compareFn = (i: any, j: any) => {
    if (i[sortBy] < j[sortBy]) {
      return sortOrder === "asc" ? -1 : 1;
    } else {
      if (i[sortBy] > j[sortBy]) {
        return sortOrder === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    }
  }
  return itemsToSort.sort(compareFn);
}

function UsersWrapper() {
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const classes = useStyles();
  const { user1 } = useStore();

  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const { enqueueSnackbar } = useSnackbar();
  const [isForm, setIsForm] = useState(false);
  const [reload, setReload] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadUser, setLoadUser] = useState(false);
  const saveEditUser = () => {
    setIsForm(false);
    setIsEdit(false);
    setLoadUser(!loadUser);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menu, setMenu] = useState([] as any);
  const handleClick = (item: any) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    menu[item] = true;
    setMenu(menu);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item: any) => () => {
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
  };
  const [deluser, setDelUser] = useState("");
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [appid, setAppId] = useState("");
  const dialogHandleDelete = (user: any) => {
    setOpenDialogDelete(true);
    setDelUser(user.user_id);
    setAppId(user.application_id);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
  };
  const [exportpopup, setExportPopupOpen] = useState(false);
  const dialogHandleCloseDelete = () => {
    setOpenDialogDelete(false);
    setExportPopupOpen(false);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
    setExportRadioBtn({
      app: true,
      app_level: true,
      email_id: true,
      role_name: true,
      valid_to: true,
      last_login: true,
      status: true
    })
  };
  const dialogHandlesaveDelete = () => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const USERS_API = `${API["GETALLAPPLS"]}/${appid}/users/${deluser}?pm_s_token=${pmST}&pm_s_id=${pmSId}`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    };
    fetch(USERS_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
        if (response && response.status === 200) {
          setOpenDialogDelete(false);
          setDialogOpen(false);
          enqueueSnackbar("User Deleted", { variant: "success" });
          const menuClose = menu.map((m: any) => false);
          setMenu(menuClose);
          setAnchorEl(null);
          setLoadUser(!loadUser);
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              dialogHandlesaveDelete()
            }).catch(function (error) {
              user1.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user1.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else {
          setError(true);
          setErrMsg(
            "Error occured. Please try again after sometime."
          );
        }
      })
    })
      .catch(function (error) {
        console.log(error)
      });

  };
  const [openDialog, setDialogOpen] = useState(false);
  const [viewUser, setViewUserData] = useState([] as any);

  const dialogHandleClickOpen = (user: any) => {
    setViewUserData(user);
    setDialogOpen(true);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
  };

  const dialogHandleClose = () => {
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
    setDialogOpen(false);
  };
  const [editItem, setEditItem] = useState({} as any);
  const [userRoles, setUserRoles] = useState([] as any);

  const handleEditUser = (item: any) => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const USERS_ROLES = `${API["GETALLAPPLS"]}/${app}/roles?pm_s_token=${pmST}&pm_s_id=${pmSId}`;
    Promise.all([fetch(USERS_ROLES, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    })])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response) {
        if ((response && response[0].status === 401) && (response && arrayOfErrorsRefresh.includes(response[0].message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              handleEditUser(item);
              setbackDrop(false);
            }).catch(function (error) {
              user1.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response[0].status === 401) && (response && arrayOfErrorsLogout.includes(response[0].message.toLowerCase()))) {
          try {
            user1.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (
          response &&
          response[0].status === 200 &&
          typeof response[0].roles !== "undefined"
        ) {
          setUserRoles(response[0].roles);
        }
      })
      .catch(function (error) {

      });

    setDialogOpen(false);
    setEditItem(item);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
    setIsEdit(true);
  };
  const [backDrop, setbackDrop] = useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };
  const [application, setApplication] = useState([] as any);
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const updateError = () => {
    setError(false);
    setErrMsg("");
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClickPaginate = (offset: number) => {
    setOffset(offset);
    setPage(offset / rowsPerPage);
  };
  const [offset, setOffset] = useState(0);

  const [exportRadioBtn, setExportRadioBtn] = useState({
    app: true,
    app_level: true,
    email_id: true,
    role_name: true,
    valid_to: true,
    last_login: true,
    status: true
  });
  const handleChange = (event: any) => {
    setExportRadioBtn({
      ...exportRadioBtn,
      [event.target.name]: event.target.checked,
    });
  };
  const handleExport = async () => {
    if (exportRadioBtn.app === false && exportRadioBtn.app_level === false && exportRadioBtn.role_name === false && exportRadioBtn.email_id === false && exportRadioBtn.valid_to === false && exportRadioBtn.last_login === false && exportRadioBtn.status === false) {
      enqueueSnackbar("Please select at least one column to export.", { variant: "error" });
    } else {
      setTimeout(() => {
        setExportPopupOpen(false);
      }, 500);
      setbackDrop(true);
      const exclData = [] as any;
      userApplicationData.forEach((item: any) => {
        const row: any = {};
        if (exportRadioBtn.app) {
          row.Application = item.application_name;
        }
        if (exportRadioBtn.app_level) {
          row.Application_level = item.application_level ? item.application_level[0] ? item.application_level[0].level_name ? item.application_level[0].level_name : "NA" : "NA" : "NA";
        }
        if (exportRadioBtn.email_id) {
          row.EmailID = item.email_id;
        }
        if (exportRadioBtn.role_name) {
          row.Role_name = item.role_name;
        }
        if (exportRadioBtn.valid_to) {
          row.ValidTo = item.valid_to;
        }
        if (exportRadioBtn.last_login) {
          row.LastLogin = item.last_login;
        }
        if (exportRadioBtn.status) {
          row.Status = item.status;
        }
        exclData.push(row);

      });
      exportToExcel(exclData, "Users", exclData.length);
      setbackDrop(false);
      enqueueSnackbar("Export initiated.", { variant: "success" });
      setTimeout(() => {
        setLoadUser(!loadUser);
        setExportRadioBtn({
          app: true,
          app_level: true,
          email_id: true,
          role_name: true,
          valid_to: true,
          last_login: true,
          status: true
        })
      }, 500);
    }
  };

  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleCloseSearch = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setSearchActive(false);

  };
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };
  const [selectedApplication, setSelectedApplication] = useState([] as any);
  const [selectedApplicationSummery, setSelectedApplicationSummery] = useState({} as any);

  const [userApplicationData, setUserApplicationData] = useState(
    [] as any
  );
  const [app, setApp] = useState("" as any);

  // const onChangeApplication = (event: any) => {
  //   setApp(event.target.value);
  //   setSelectedApplication(userApplicationData.filter(function filterUser(user: any) {
  //     return user.application_name === event.target.value
  //   }));
  // }
  const [defaultSortBy, setDefaultSortBy] = useState("email_id");
  const [defaultSortOrder, setDefaultSortOrder] = useState("asc");
  const sortedItems = getSortedItem(defaultSortBy, defaultSortOrder, selectedApplication);
  const [sortedItemRows, setSortItemRows] = useState(sortedItems);

  useEffect(() => {
    let rows = selectedApplication;

    if (search.length) {
      setOffset(0);
      setPage(0);
      rows = getFilteredItem(selectedApplication, search)
    }
    const sortedItems = getSortedItem(defaultSortBy, defaultSortOrder, rows);
    setSortItemRows(sortedItems);
  }, [defaultSortBy, defaultSortOrder, selectedApplication, search])

  const sortByColumn = (columnName: any) => {
    if (columnName === defaultSortBy) {
      setDefaultSortOrder(defaultSortOrder === "asc" ? "desc" : "asc");
    } else {
      setDefaultSortOrder("asc");
      setDefaultSortBy(columnName)
    }
  }

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sObj {
      pm_s_id: string;
    }
    interface tObj {
      pm_s_token: string;
    }
    let pmSId: sObj = {
      pm_s_id: "",
    };
    let pmST: tObj = {
      pm_s_token: "",
    };
    pmSId =
      sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
    pmST =
      sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
    setbackDrop(true);
    const APPLICATION_API = API["GETALLAPPLS"];
    const USERS_API = API["GETALLUSERS"];

    Promise.all([
      fetch(`${APPLICATION_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        setbackDrop(false);
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              setReload(!reload)
            }).catch(function (error) {
              user1.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user1.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (
          response &&
          response[0].status === 200 &&
          typeof response[0].applications !== "undefined"
        ) {
          setApplication(response && response[0].applications);
          handleSelectApplication(
            {
              target: {
                value: response[0].applications[0].application_id
              }
            })
          setApp(response && response[0].applications[0].application_id)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }, [loadUser, reload, accessToken.access_token, API_KEY, isForm]);


  const callToExport = () => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
   
    const USERS_API = API["GETALLUSERS"];

    Promise.all([
      fetch(`${USERS_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        setbackDrop(false);
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              setReload(!reload)
            }).catch(function (error) {
              user1.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user1.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (
          response &&
          response[0].status === 200 && (response[0].users).length > 0 &&
          typeof response[0 ].users !== "undefined"
        ) {
          setUserApplicationData(response && response[0].users);
          setExportPopupOpen(true);
        }  
      })
      .catch(function (error) {
        console.log(error)
      });
  };


  
  const handleSelectApplication = (event: any) => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    setApp(event.target.value);

    setbackDrop(true);
    const GET_USER_API =
      API["GETALLAPPLS"] +
      `/${event.target.value}/users?pm_s_token=${pmST}&pm_s_id=${pmSId}`;
    Promise.all([
      fetch(GET_USER_API, {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        setbackDrop(false);
        if ((response && response[0].status === 401) && (response && arrayOfErrorsRefresh.includes(response[0].message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              handleSelectApplication(event);
            }).catch(function (error) {
              user1.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response[0].status === 401) && (response && arrayOfErrorsLogout.includes(response[0].message.toLowerCase()))) {
          try {
            user1.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }

        setSelectedApplication(response[0].users);
        setSelectedApplicationSummery(response[0].summary)
      
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  if(!selectedApplication || !selectedApplicationSummery) {
    return (
      <div className={classes.permissionError}>
        An error occured while loading user list
        <strong>Please Check Your Permissions</strong>
      </div>
    )
  }


  return (
    <React.Fragment>
      <div className={isForm || isEdit ? classes.hidden : classes.gridRootApp}>
        <Grid container spacing={0} className={classes.alignCentre}>
          <label className={classes.label}>Application: </label>
          <select
            className={classes.dropdown}
            onChange={handleSelectApplication}
            value={app}
          >
            {Array.isArray(application) && application.map((option: any, index: any) => (
              <option
                value={option.application_id}
                label={option.application_name}
              >
                {option.application_name}
              </option>
            ))}
          </select>
        </Grid>
      </div>
      <div className={classes.gridRoot} style={{display: 'flex',alignItems: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
         {isForm?<BreadcumbComponent route='addUser' func={saveEditUser}/>:isEdit?<BreadcumbComponent route='editUser' func={saveEditUser}/>:<BreadcumbComponent route='users'/>}
      </div>
      <div className={isForm || isEdit ? classes.hidden : ""}>
        <div className={classes.gridRoot}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={7}>
              <List classes={{ root: classes.flexContainer }} dense={true}>
                <ListItem classes={{ root: classes.listItem }}>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary="Total Users:"
                    primaryTypographyProps={{
                      style: { display: `${displayVar}` },
                    }}
                    secondary={<span>{selectedApplicationSummery.total_users}</span>}
                    secondaryTypographyProps={{
                      style: {
                        display: `${displayVar}`,
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: "16px",
                      },
                    }}
                  />
                </ListItem>
                <ListItem classes={{ root: classes.listItem }}>
                  <ListItemText
                    primary="Active Users:"
                    classes={{ primary: classes.listItemText }}
                    primaryTypographyProps={{
                      style: { display: `${displayVar}` },
                    }}
                    secondary={selectedApplicationSummery.active_users}
                    secondaryTypographyProps={{
                      style: {
                        display: `${displayVar}`,
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: "16px",
                      },
                    }}
                  />
                </ListItem>
                <ListItem classes={{ root: classes.listItem }}>
                  <ListItemText
                    primary="Inactive Users:"
                    classes={{ primary: classes.listItemText }}
                    primaryTypographyProps={{
                      style: { display: `${displayVar}` },
                    }}
                    secondary={selectedApplicationSummery.inactive_users}
                    secondaryTypographyProps={{
                      style: {
                        display: `${displayVar}`,
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: "16px",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  classes={{ root: classes.listItem }}
                  className={classes.listButtonWrap}
                >
                  <Button
                    variant="text"
                    color="inherit"
                    className={classes.listButton}
                    startIcon={<img alt="Export Logo" src={exportLogo}></img>}
                    onClick={() => callToExport()}
                  >
                    Export
                  </Button>
                </ListItem>
                <ListItem
                  classes={{ root: classes.listItem }}
                  className={classes.listButtonWrap}
                >
                  <ClickAwayListener onClickAway={handleCloseSearch}>
                    {searchActive || search !== "" ? (
                      <CssTextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: false,
                          className: classes.labels,
                        }}
                        autoComplete="off"
                        value={search}
                        onChange={(event) => handleChangeSearch(event)}
                        id="filled-search"
                        type="search"
                        variant="filled"
                      />
                    ) : (
                      <Button
                        variant="text"
                        color="inherit"
                        className={classes.listButton}
                        startIcon={<SearchIcon style={{ color: "#7C7C94" }} />}
                        onClick={() => setSearchActive(true)}
                      >
                        Search
                      </Button>
                    )}
                  </ClickAwayListener>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={5}>
              <div className={classes.rightSec}>
                <Button
                  variant="contained"
                  color="inherit"
                  className={classes.listButton}
                  endIcon={<AddIcon />}
                  size="large"
                  onClick={() => {
                    setIsForm(true);
                  }}
                >
                  Add New User
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Backdrop
          className={classes.backdrop}
          open={backDrop}
          onClick={backDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <TableContainer component={Paper} className={classes.tableWrapper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell><StyledTableSort
                  active={defaultSortBy === "email_id"}
                  direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                  onClick={() => sortByColumn("email_id")}
                  IconComponent={ArrowDropDown}
                  style={{ color: 'white' }}
                >
                  Email ID
                </StyledTableSort></StyledTableCell>
                <StyledTableCell><StyledTableSort
                  active={defaultSortBy === "valid_to"}
                  direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                  onClick={() => sortByColumn("valid_to")}
                  IconComponent={ArrowDropDown}
                  style={{ color: 'white' }}
                >
                  Valid To
                </StyledTableSort></StyledTableCell>
                <StyledTableCell><StyledTableSort
                  active={defaultSortBy === "last_login"}
                  direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                  onClick={() => sortByColumn("last_login")}
                  IconComponent={ArrowDropDown}
                  style={{ color: 'white' }}
                >
                  Last Login
                </StyledTableSort></StyledTableCell>
                <StyledTableCell>
                  <StyledTableSort
                    active={defaultSortBy === "status"}
                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => sortByColumn("status")}
                    IconComponent={ArrowDropDown}
                    style={{ color: 'white' }}
                  >
                    Status
                  </StyledTableSort>
                </StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
              </TableRow>
            </TableHead>
            {selectedApplication.length > 0 ? (
              <TableBody>
                {(sortedItemRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )).map((row: any, index: number) => (
                  <StyledTableRow key={row.user_id}>
                    <StyledTableCell>{row.email_id}</StyledTableCell>
                    <StyledTableCell>{row.valid_to}</StyledTableCell>
                    <StyledTableCell>
                      {row.last_login ? row.last_login : " "}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: row.status === "ACTIVE" ? "#2AC392" : "#D72B3F",
                      }}
                    >
                      {row.status ? row.status : " "}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={handleClick(index)}
                        classes={{ root: classes.menuButtonRoot }}
                      >
                        <MoreVertIcon color="secondary" />
                      </IconButton>
                      <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        open={menu[index]}
                        onClose={handleClose(index)}
                        classes={{ paper: classes.menuRoot }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          className={`${classes.menuitem} ${classes.viewDetails}`}
                          onClick={() => dialogHandleClickOpen(row)}
                        >
                          <VisibilityIcon fontSize="small"></VisibilityIcon>
                          View Details
                        </MenuItem>
                        <MenuItem
                          className={`${classes.menuitem} ${classes.viewDetails}`}
                          data-key={row.user_id}
                          onClick={() => handleEditUser(row)}
                        >
                          <EditIcon></EditIcon>
                          Edit
                        </MenuItem>
                        <MenuItem
                          className={`${classes.menuitem} ${classes.viewDetails}`}
                          onClick={() => dialogHandleDelete(row)}
                        >
                          <DeleteOutlinedIcon></DeleteOutlinedIcon>
                          Delete
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <div style={{ color: "white", paddingTop: "1.2rem" }}>
                No Records Found.
              </div>
            )}
          </Table>
        </TableContainer>


        <Grid container xs={12} direction='row' alignItems='center' justify='space-between' style={{ paddingLeft: '1.6em' }}>
          <Grid xs={4} item container direction='row' alignItems='center'>
            <Typography className={classes.paginateView}>
              View:{" "}
            </Typography>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={sortedItemRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={"User per page :"}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.tblpaginate}
            />
            <Typography className={classes.paginateUser}>
              Users per page
            </Typography>
          </Grid>
          <Pagination
            limit={rowsPerPage}
            offset={offset}
            onClick={(e, offset) => handleClickPaginate(offset)}
            total={sortedItemRows.length}
            className={classes.tblpaginatenumber}
            currentPageColor="secondary"
            otherPageColor="primary"
          />
        </Grid>
        <Dialog
          classes={{ paper: classes.dialogRoot }}
          onClose={dialogHandleClose}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
        >
          <DialogTitle id="customized-dialog-title" onClose={dialogHandleClose}>
            User Details
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={0} className="dcOuter dcSep">
              <Grid item xs={4}>
                <Typography className="dcTitle">Application</Typography>
                <Typography className="dcFieldValue">{viewUser.application_name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="dcTitle">Role</Typography>
                <Typography className="dcFieldValue">
                  {viewUser.role_name}
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Typography className="dcTitle" style={{ marginTop: "10px" }}>Valid From</Typography>
                <Typography className="dcFieldValue">
                  {viewUser.valid_from}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="dcTitle" style={{ marginTop: "10px" }}>Valid To</Typography>
                <Typography className="dcFieldValue">
                  {viewUser.valid_to}
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              className="dcBtDelete"
              onClick={() => dialogHandleDelete(viewUser)}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              className="dcBtEdit"
              onClick={() => handleEditUser(viewUser)}
            >
              Edit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{ paper: classes.dialogRootSmall }}
          onClose={dialogHandleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={openDialogDelete}
        >
          <Grid container direction="row" alignItems="center">
            <img
              style={{ marginLeft: "1em" }}
              alt="Delete Logo"
              src={deleteLogo}
            ></img>
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseDelete}
            >
              Delete User?
            </DialogTitle>
          </Grid>
          <DialogContent>
            <Typography
              style={{
                fontFamily: "Avenir Next",
                fontSize: "14px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                margin: "1em",
                color: "#FFFFFF",
              }}
            >
              Are you sure you want to delete this User? This User cannot be
              recovered.
            </Typography>
          </DialogContent>
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseDelete}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={dialogHandlesaveDelete}
              variant="contained"
              className="dcBtEdit"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{ paper: classes.dialogRoot }}
          onClose={dialogHandleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={exportpopup}
        >
          <Grid container direction="row" alignItems="center">
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseDelete}
            >
              Select Columns to Export
            </DialogTitle>
          </Grid>
          <DialogContent>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.app}
                      onChange={handleChange}
                      name="app"
                    />
                  }
                  label="Application"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.app_level}
                      onChange={handleChange}
                      name="app_level"
                    />
                  }
                  label="Application Level"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.email_id}
                      onChange={handleChange}
                      name="email_id"
                    />
                  }
                  label="Email ID"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.role_name}
                      onChange={handleChange}
                      name="role_name"
                    />
                  }
                  label="Role Name"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.valid_to}
                      onChange={handleChange}
                      name="valid_to"
                    />
                  }
                  label="Valid To"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.last_login}
                      onChange={handleChange}
                      name="last_login"
                    />
                  }
                  label="Last Login"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.status}
                      onChange={handleChange}
                      name="status"
                    />
                  }
                  label="Status"
                />
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
              </FormGroup>
            </FormControl>
          </DialogContent>
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseDelete}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleExport}
              variant="contained"
              className="dcBtEdit"
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={!isForm ? classes.hidden : ""}>
        <AddNewUser onSaveUser={saveEditUser} />
      </div>
      <div className={!isEdit ? classes.hidden : ""}>
        <EditUser
          editItem={editItem}
          userRoles={userRoles}
          onEditUser={saveEditUser}
        />
      </div>
      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </React.Fragment>
  );
}

export default function Users() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <UsersWrapper />
    </SnackbarProvider>
  );
}
