import React from "react";
import { Typography } from "@material-ui/core";

import CertificateStatusIndicator from "../CerificateStatusIndicator";
import DeviceHealthReport from "../../../DeviceHealthReport"; //TODO: Bring inside the sandbox
import getActionMenuItems from "./getActionMenuItems";
import TimeAgoRenderer from "../../../../TimeAgoRenderer";
import { COLUMN_IDS } from "./columns";

const COLUMN_TEMPLATES = {
  [COLUMN_IDS.action]: getActionMenuItems,
  [COLUMN_IDS.certificate_expiring]: ({ record }: any) => (
    <CertificateStatusIndicator record={record} />
  ),
  [COLUMN_IDS.is_healthy]: ({ record }: any) =>
    record.is_healthy === false ? <DeviceHealthReport /> : null,
  [COLUMN_IDS.status]: ({ record }: any) =>
    record.status === "ACTIVE" ? (
      <div style={{ color: "#2AC392" }}>{record.status}</div>
    ) : (
      record.status
    ),
  [COLUMN_IDS.battery_level]: ({ record }: any) =>
    +record.battery_level === -1 ? (
      <Typography style={{ color: "#D72B3F" }}>INVALID DATA</Typography>
    ) : (
      record.battery_level
    ),
  [COLUMN_IDS.battery_unit]: ({ record }: any) => {
    if (+record.battery_level === -1) {
      return "";
    }
    const loweredBatteryUnit = (record.battery_unit || "").toLowerCase();
    if (loweredBatteryUnit === "per") {
      return "%";
    } else if (loweredBatteryUnit === "v") {
      return "V";
    }
    return record.battery_unit;
  },
  [COLUMN_IDS.pi_measurement]: ({ record }: any) =>
    +record.pi_measurement === -1 ? (
      <Typography style={{ color: "#D72B3F" }}>INVALID DATA</Typography>
    ) : (
      record.pi_measurement
    ),
  [COLUMN_IDS.pi_timestamp]: ({ record }: any) => {
    if (+record.pi_timestamp === -1) {
      return <Typography style={{ color: "#D72B3F" }}>INVALID DATA</Typography>;
    }
    return <TimeAgoRenderer dateTime={record.pi_timestamp} />;
  },
};

const getColumnTemplate = (columnId: string) => {
  return COLUMN_TEMPLATES[columnId];
};

export default getColumnTemplate;
