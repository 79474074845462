import React from "react";
import { Button } from "@material-ui/core";

interface ICustomButtonProps {
  onClick: () => void;
  variant: "outlined-green" | "outlined-red" | "outlined-white";
  style?: React.CSSProperties;
}

//TODO: Merge similar styles. Create a map if required
//TODO: Add submit button as a variant
const CustomButton: React.FC<ICustomButtonProps> = ({
  variant,
  onClick,
  children,
  style = {},
}) => {
  if (variant === "outlined-green") {
    return (
      <Button
        onClick={onClick}
        size="small"
        style={{
          color: "green",
          textTransform: "none",
          border: "1px solid green",
          ...style,
        }}
      >
        {children}
      </Button>
    );
  }

  if (variant === "outlined-red") {
    return (
      <Button
        onClick={onClick}
        size="small"
        style={{
          color: "#CD5C5C",
          textTransform: "none",
          border: "1px solid #CD5C5C",
          ...style,
        }}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      style={{
        color: "#FCFCFC",
        borderColor: "#FCFCFC",
        textTransform: "none",
        marginTop: "5px",
        ...style,
      }}
      variant="outlined"
      component="span"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
