import React from "react";
import { FormSection, GriddedTextField } from "../../../UiComponents";

const Columns: React.FC<any> = ({ tableName, dropdowns, onDropdownUpdate }) => {
  return (
    <>
      {Object.keys(dropdowns).map((columnName: any) => {
        return (
          <GriddedTextField
            key={columnName}
            value={dropdowns[columnName]}
            label={`${tableName}: ${columnName} (Enter possible field values separated by | )`}
            onChange={(value: string) =>
              onDropdownUpdate(tableName, columnName, value)
            }
          />
        );
      })}
    </>
  );
};

const TabularDropdowns: React.FC<any> = ({ dropdowns, onDropdownUpdate }) => {
  return (
    <FormSection header="Dropdowns for Tabular Tags">
      {Object.keys(dropdowns).map((tableName: any) => {
        return (
          <Columns
            key={tableName}
            tableName={tableName}
            dropdowns={dropdowns[tableName]}
            onDropdownUpdate={onDropdownUpdate}
          />
        );
      })}
    </FormSection>
  );
};

export default TabularDropdowns;
