import React, { useEffect, useState } from "react";
import { useSnackbar, VariantType } from "notistack";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  SubmitButton,
  LoadingIndicator,
  CustomButton,
} from "../../../UiComponents";
import useApi from "../../../../hooks/useApi";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";

import InstallationStatusDropdown from "./InstallationStatusDropdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minWidth: "30em",
      margin: "1em 0",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    dropDownHolder: {
      width: "100%",
      marginBottom: "3em",
    },
    buttonHolder: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: "1px solid #3d3d3d",
      paddingTop: "1em",
    },
  })
);

interface IUpdateInstallationStatusModalProps {
  open: boolean;
  onClose: () => void;
  deviceName: string;
  deviceDlmId: string;
  installStatus: string;
  installationStatusId: string;
  onSuccess: () => void;
}

const INSTALLATION_STATUS_UPDATE_RESULTS = {
  UNAUTHORIZED: "INVALID_DEVICE_INSTALL_STATUS",
  SUCCESS: "Device status updated successfully",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
};

const getOperationStatus = (deviceUpdateResult: string) => {
  return {
    [INSTALLATION_STATUS_UPDATE_RESULTS.SUCCESS]: {
      message: "Device installation status updated successfully",
      variant: "success",
    },
    [INSTALLATION_STATUS_UPDATE_RESULTS.UNAUTHORIZED]: {
      message: "You are not authorized to perform this action",
      variant: "error",
    },
    [INSTALLATION_STATUS_UPDATE_RESULTS.UNKNOWN_ERROR]: {
      message: "Unexpected error occured while updating",
      variant: "error",
    },
  }[deviceUpdateResult];
};

const UpdateInstallationStatusModal: React.FC<
  IUpdateInstallationStatusModalProps
> = ({
  open,
  onClose,
  deviceName,
  deviceDlmId,
  installationStatusId: currentInstallationStatusId,
  installStatus,
  onSuccess,
}) => {
  const classes = useStyles();
  const [selectedInstallationStatusId, setSelectedInstallationStatusId] =
    useState(currentInstallationStatusId);
  const { selectedApplication } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const {
    status,
    data: deviceUpdateResult,
    trigger: updateInstallationStatus,
  } = useApi(
    `/applications/${selectedApplication.application_id}/device-installation/${deviceDlmId}`,
    {
      deferred: true,
      method: "PUT",
      responseDataFormatter: (data: any) =>
        data?.data || INSTALLATION_STATUS_UPDATE_RESULTS.UNKNOWN_ERROR,
    }
  );

  useEffect(() => {
    const { message, variant } = getOperationStatus(deviceUpdateResult) || {};
    if (!status.pending && message) {
      enqueueSnackbar(message, { variant: variant as VariantType });
      variant === "success" && onSuccess();
    }
    if (!status.pending && status.error) {
      enqueueSnackbar(
        status.error.includes("401")
          ? "You are not authorized to perform this operation"
          : "Unexpected error occured",
        { variant: "error" }
      );
    }
  }, [deviceUpdateResult, status.pending, status.error]);

  const canSave =
    selectedInstallationStatusId &&
    selectedInstallationStatusId !== currentInstallationStatusId;

  return (
    <Modal
      title={`Installation Status: ${deviceName}`}
      open={open}
      onClose={() => onClose()}
    >
      <div className={classes.wrapper}>
        <div className={classes.dropDownHolder}>
          <InstallationStatusDropdown
            onChange={(selectedValue) =>
              setSelectedInstallationStatusId(selectedValue)
            }
            currentInstallStatusLabel={installStatus}
            value={selectedInstallationStatusId}
          />
        </div>
        <div className={classes.buttonHolder}>
          <CustomButton variant="outlined-white" onClick={onClose}>
            Cancel
          </CustomButton>
          <SubmitButton
            onClick={() => {
              updateInstallationStatus({
                old_status_id: currentInstallationStatusId,
                new_status_id: selectedInstallationStatusId,
              });
            }}
            disabled={!canSave}
          >
            Save
          </SubmitButton>
        </div>

        {status.pending && <LoadingIndicator />}
      </div>
    </Modal>
  );
};

export default UpdateInstallationStatusModal;
