import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import callXhrRequest from "../../../../../utils/xhrRequestHandler";
import FormControl from "@material-ui/core/FormControl";
import { useSnackbar } from "notistack";
import { useStore } from "mobx-store-provider";
import { API } from "../../../../../api/property";
import axios from "axios";
import {
  arrayOfErrorsRefresh,
  arrayOfErrorsLogout,
} from "../../../../../utils/helper";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
const shadow_style = "0px 30px 100px #000000";
const textFieldBorder = "1px solid #464659";
const border_style = "1px solid rgba(235,235,245,0.35)";

const tokenKey = "sso_token";
const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "850px",
      minWidth: "850px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRoot1: {
      backgroundColor: "#2B2B36",
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "1200px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceMoreRight: {
      paddingRight: "18px",
      display: "flex",
      alignItems: "center",
      marginTop: "36px",
      marginBottom: "10px",
    },
    spaceMoreLeft: {
      paddingLeft: "18px",
      display: "flex",
      alignItems: "center",
      marginTop: "36px",
      marginBottom: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "27px 37px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const CssTextField = withStyles({
  root: {
    "&.tagInput svg": {
      position: "absolute",
      left: "8px",
      color: "#ebebf599",
    },
    "&.tagInput .MuiInput-underline": {
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
    },
    "&.tagInput input": {
      paddingLeft: "40px",
      marginBottom: "16px",
    },
    "&.tagInput label": {
      fontWeight: 600,
    },
    "&.inline": {
      display: "flex",
      alignItems: "center",
    },
    "&.inline label": {
      display: "none",
    },
    "&.inline label + .MuiInput-formControl": {
      marginTop: 0,
    },
    "&.inline input": {
      marginTop: 0,
    },
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#FCFCFC",
    },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
    width: "100%",
    height: "180px",
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#464659",
      borderRadius: "4px",
    },
    "& .MuiFormControlLabel-label": {
      width: "100%",
      "& p": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    "& .MuiButtonBase-root": {
      color: "#7C7C94",
    },
    "& .MuiButtonBase-root.Mui-checked": {
      color: "#0089FF",
      "& svg:nth-child(2)": {
        width: "40px",
        height: "40px",
        left: "-8px",
        top: "-8px",
      },
    },
    "& .MuiButtonBase-root.Mui-checked span:first-child": {
      "&::before": {
        height: "7px",
        width: "7px",
        content: "''",
        display: "block",
        top: "50.5%",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        zIndex: 9,
        borderRadius: "50%",
      },
    },
  },
})(FormControl);

const DialogTitle = withStyles(DialogTitleStyles)((prop: DialogTitleProps) => {
  const { children, classes, ...other } = prop;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
        className={classes.title}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "37px",
    marginTop: 0,
    backgroundColor: "#2B2B36",
    color: "#FFFFFF",
  },
  button: {
    textTransform: "inherit",
  },
}))(MuiDialogContent);

const SingleDialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "37px",
    color: "#FFFFFF",
    marginTop: "20px",
  },
  button: {
    textTransform: "inherit",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 62px 33px 62px",
    justifyContent: "space-between",
    paddingTop: "20px",
    borderTop: "1px solid #33333F",
    "& a": {
      textDecoration: "none",
    },
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

const CertificateHandler = (props: any) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useStore();
  const [tags, setTags] = React.useState([] as any);
  const [deviceDetails, setDeviceDetails] = React.useState([] as any);
  const [tagPresent, setTagPresent] = React.useState(false as any);
  const [popupType, setPopupType] = React.useState("");
  const [dataUrl, setDataUrl] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const aElm = React.useRef(null as any);
  const aElm1 = React.useRef(null as any);

  interface sessionObj {
    pm_s_id: string;
  }
  interface tokenObj {
    pm_s_token: string;
  }
  let pmSessionId: sessionObj = {
    pm_s_id: "",
  };
  let pmSessionToken: tokenObj = {
    pm_s_token: "",
  };

  const deviceDownloadFunc = () => {
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const CERT_DOWNLOAD_API = `${API["GETALLAPPLS"]}/${props.appId}/devices/${props.device.device_dlm_id}/device-cert?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    axios({
      method: "get",
      url: CERT_DOWNLOAD_API,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
    })
      .then((response: any) => {
        if (response.data.type === "application/x-pkcs12") {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          setDataUrl(url);
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err.response &&
          err.response.data &&
          err.response.data.status === 401 &&
          err.response.data.message &&
          arrayOfErrorsRefresh.includes(err.response.data.message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                revokeFunc();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.status === 401 &&
          err.response.data.message &&
          arrayOfErrorsLogout.includes(err.response.data.message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        } else {
          if (err.response.data?.message) {
            enqueueSnackbar(err.response.data.message, { variant: "error" });
          } else {
            enqueueSnackbar(
              "Error occured while downloading certificate. Please try again.",
              { variant: "error" }
            );
          }
        }
      });
  };

  const revokeFunc = () => {
    setLoader(true);
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const CERT_REVOKE_API = `${API["GETALLAPPLS"]}/${props.appId}/devices/${props.device.device_dlm_id}/revoke-device-cert?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    axios({
      method: "put",
      url: CERT_REVOKE_API,
      headers: {
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
    })
      .then((response: any) => {
        setLoader(false);
        console.log(response);
        enqueueSnackbar(response.data.message, { variant: "success" });
        props.close();
      })
      .catch((err) => {
        setLoader(false);
        console.log(err.response);
        if (
          err.response &&
          err.response.data &&
          err.response.data.status === 401 &&
          err.response.data.message &&
          arrayOfErrorsRefresh.includes(err.response.data.message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                revokeFunc();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.status === 401 &&
          err.response.data.message &&
          arrayOfErrorsLogout.includes(err.response.data.message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        } else {
          if (err.response.data?.message) {
            enqueueSnackbar(err.response.data.message, { variant: "error" });
          } else {
            enqueueSnackbar(
              "Error occured while revoking certificate. Please try again.",
              { variant: "error" }
            );
          }
        }
      });
  };

  const renewFunc = () => {
    setLoader(true);
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const CERT_RENEW_API = `${API["GETALLAPPLS"]}/${props.appId}/devices/${props.device.device_dlm_id}/renew-leaf-cert?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    axios({
      method: "put",
      url: CERT_RENEW_API,
      headers: {
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
    })
      .then((response: any) => {
        setLoader(false);
        console.log(response);
        enqueueSnackbar(response.data.message, { variant: "success" });
        props.close();
      })
      .catch((err) => {
        setLoader(false);
        console.log(err.response);
        if (
          err.response &&
          err.response.data &&
          err.response.data.status === 401 &&
          err.response.data.message &&
          arrayOfErrorsRefresh.includes(err.response.data.message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                revokeFunc();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.status === 401 &&
          err.response.data.message &&
          arrayOfErrorsLogout.includes(err.response.data.message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        } else {
          if (err.response.data?.message) {
            enqueueSnackbar(err.response.data.message, { variant: "error" });
          } else {
            enqueueSnackbar(
              "Error occured while revoking certificate. Please try again.",
              { variant: "error" }
            );
          }
        }
      });
  };

  React.useEffect(() => {
    setDataUrl("");
    setPopupType(props.popupType);
    if (
      props.device.device_dlm_id &&
      props.open &&
      props.popupType === "single_download"
    ) {
      deviceDownloadFunc();
      let temp = [deviceName, lastUpdate];
      setTags(temp);
    } else if (
      props.device.device_dlm_id &&
      props.open &&
      props.popupType === "revoke"
    ) {
      let temp = [deviceName, deviceId];
      setTags(temp);
    }
  }, [props.device, props.open, props.popupType]);

  const downloadFunc = () => {
    if (aElm && aElm.current && dataUrl !== "") {
      aElm.current.href = dataUrl;
      aElm.current.setAttribute("download", `${props.device.device_id}.pfx`);
      // aElm.current.click();
    }
  };

  const singleDownloadUi = (
    <React.Fragment>
      <SingleDialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3">
              Are you sure want to download this device certificate
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          {tags.map((tag: any, index: any) => (
            <Grid
              item
              key={index}
              xs={6}
              className={
                index % 2 === 1 ? classes.spaceMoreLeft : classes.spaceMoreRight
              }
            >
              <Typography style={{ width: "50%" }}>{tag.fieldName}</Typography>
              <CssTextField
                fullWidth
                name={tag.fieldName}
                label={tag.fieldName}
                type={tag.fieldType}
                id={tag.fieldName}
                className={"inline"}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                onChange={(event) => console.log(event)}
                value={tag.value}
                disabled={tag.disabled}
              />
            </Grid>
          ))}
        </Grid>
      </SingleDialogContent>
      <DialogActions>
        <Button onClick={props.close} variant="outlined" className="dcBtDelete">
          Cancel
        </Button>
        <a
          onClick={downloadFunc}
          ref={aElm}
          href="#"
          style={{
            pointerEvents: dataUrl === "" ? "none" : "visible",
            cursor: dataUrl === "" ? "default" : "default",
            opacity: dataUrl === "" ? ".5" : "1",
          }}
        >
          <Button variant="contained" className="dcBtEdit">
            Download
          </Button>
        </a>
      </DialogActions>
    </React.Fragment>
  );

  const revokeUi = (
    <React.Fragment>
      <DialogTitle id="customized-dialog-title" onClose={props.close}>
        <span>Revoke Certificate</span>
        <span
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Expired Date: {props.device?.cert_exp_date}
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ marginBottom: "30px" }}>
          {tags.map((tag: any, index: any) => (
            <Grid
              item
              key={index}
              xs={6}
              className={
                index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
              }
            >
              <CssTextField
                fullWidth
                name={tag.fieldName}
                label={tag.fieldName}
                type={tag.fieldType}
                id={tag.fieldName}
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                onChange={(event) => console.log(event)}
                value={tag.value}
                disabled={tag.disabled}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} variant="outlined" className="dcBtDelete">
          Cancel
        </Button>
        <a
          onClick={revokeFunc}
          ref={aElm1}
          href="#"
          style={{
            pointerEvents: loader ? "none" : "visible",
            cursor: loader ? "default" : "pointer",
            opacity: loader ? ".5" : "1",
          }}
        >
          <Button
            variant="contained"
            className="dcBtEdit"
            style={{
              minWidth: "194px",
              borderRadius: "2px",
              marginRight: "10px",
            }}
          >
            Revoke
          </Button>
        </a>
      </DialogActions>
    </React.Fragment>
  );

  const renewUi = (
    <React.Fragment>
      <DialogTitle id="customized-dialog-title" onClose={props.close}>
        <span>Are you sure want to renew this device certificate</span>
        <span
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Expiry Date: {props.device?.cert_exp_date}
        </span>
      </DialogTitle>

      <DialogActions>
        <Button onClick={props.close} variant="outlined" className="dcBtDelete">
          Cancel
        </Button>
        <a
          onClick={renewFunc}
          ref={aElm1}
          href="#"
          style={{
            pointerEvents: loader ? "none" : "visible",
            cursor: loader ? "default" : "pointer",
            opacity: loader ? ".5" : "1",
          }}
        >
          <Button
            variant="contained"
            className="dcBtEdit"
            style={{
              minWidth: "194px",
              borderRadius: "2px",
              marginRight: "10px",
            }}
          >
            Renew
          </Button>
        </a>
      </DialogActions>
    </React.Fragment>
  );

  const lastUpdate = {
    fieldName: "Last  Updated ",
    fieldType: "Text",
    value: props.device?.last_updated_on,
    options: [],
    disabled: true,
  };
  const deviceName = {
    fieldName: "Device Name",
    fieldType: "Text",
    value: props.device?.device_name,
    options: [],
    disabled: true,
  };
  const deviceId = {
    fieldName: "Device ID",
    fieldType: "Text",
    value: props.device?.device_id,
    options: [],
    disabled: true,
  };

  const popupView = () => {
    if (popupType === "single_download") {
      return singleDownloadUi;
    } else if (popupType === "revoke") {
      return revokeUi;
    } else if (popupType === "renew") {
      return renewUi;
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      {popupView()}
    </Dialog>
  );
};

export default CertificateHandler;
