import React from "react";
import { FormSection, CustomCheckbox } from "../../../UiComponents";
import CustomTextField from "../../../UiComponents/TextField/TextField";
import { Grid } from "@material-ui/core";
import useStyles from "../EditConfiguration.styles";

const getInstructionByTagType = (tagType: string) => {
  return (
    {
      dropdown: "(Enter options separated by | )",
      tabular: "(Enter column names separated by | )",
    }[tagType.toLowerCase()] || ""
  );
};

const TagRenderer: React.FC<any> = ({ name, tagData, onTagChange }) => {
  const { type, data_type, is_visible_as_column, updatedVisibility } = tagData;
  const classes = useStyles();
  const isTabularTag = type === "Tabular";
  const isDropdown = type === "Dropdown";
  return (
    <Grid item xs={6}>
      <div className={classes.tagWrapper}>
        <div className={classes.tagHeaderWrapper}>
          <div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "2px",
              }}
            >
              {`${name} ${getInstructionByTagType(type)}`}
            </div>

            <div style={{ fontSize: "12px", height: "1.35em" }}>
              {isTabularTag || !data_type ? " " : `Data Type: ${data_type}`}
            </div>
          </div>
          {!isTabularTag && (
            <CustomCheckbox
              name={name}
              checked={!!(updatedVisibility || is_visible_as_column)}
              readOnly={is_visible_as_column}
              onChange={(checkedState: boolean) =>
                onTagChange(name, {
                  ...tagData,
                  updatedVisibility: checkedState,
                })
              }
              label="Column View"
            />
          )}
        </div>
        <CustomTextField
          style={{ marginTop: "0", paddingTop: "0" }}
          value={isDropdown ? tagData.options : tagData.value}
          readOnly={isTabularTag}
          onChange={(value: string) => {
            //TODO: Make onChange and onBlur handler same
            if (isDropdown) {
              onTagChange(name, { ...tagData, options: value });
            } else {
              onTagChange(name, { ...tagData, value });
            }
          }}
          onBlur={(value: string) => {
            if (isDropdown) {
              onTagChange(name, { ...tagData, options: value.trim() });
            } else {
              onTagChange(name, { ...tagData, value: value.trim() });
            }
          }}
        />
      </div>
    </Grid>
  );
};

const Tags: React.FC<any> = ({ tags, onTagChange }) => {
  return (
    <FormSection header="Tags">
      {Object.keys(tags).map((tag: any) => (
        <TagRenderer
          name={tag}
          tagData={tags[tag]}
          onTagChange={onTagChange}
          key={tag}
        />
      ))}
    </FormSection>
  );
};

export default Tags;
