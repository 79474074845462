import React from "react";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";
import { PAGES } from "../../../../../utils/constant";
import AddRemoveImage from "../../../../UiComponents/AddRemoveImage";

const AddRemoveImageWrapper: React.FC<any> = ({ record, onDone }) => {
  const {
    selectedApplication: { application_id },
  } = useApplicationContext();
  const { device_dlm_id } = record;

  return (
    <AddRemoveImage
      deviceDataFetchEndPoint={`/applications/${application_id}/device-installation/${device_dlm_id}`}
      imageEndPoint={`/applications/${application_id}/device-installation/${device_dlm_id}/device-img`}
      deviceName={record.device_name}
      imageReader={(data: any) =>
        data?.device ? data.device.device_install_img || [] : []
      }
      requestPage={PAGES.DEVICE_INSTALLATION}
      onDone={onDone}
    />
  );
};

export default AddRemoveImageWrapper;
