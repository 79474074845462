import React from "react";
import { GriddedTextField } from "..";
import TabularFieldRenderer from "./TabularFieldRenderer";
import DropDownFieldRenderer from "./DropDownFieldRenderer";
const DynamicFieldRenderer: React.FC<any> = ({
  name,
  tagConfigData,
  tagData,
  onChange,
}) => {
  return {
    text: ({ tagData: { value } }: any) => (
      <GriddedTextField
        label={name}
        value={value}
        onChange={(v: string) => onChange(name, { value: v })}
      />
    ),
    dropdown: (props: any) => (
      <DropDownFieldRenderer name={name} onChange={onChange} {...props} />
    ),
    tabular: ({ tagData: { table_values, value } }: any) => {
      return (
        <TabularFieldRenderer
          label={name}
          columnValues={table_values}
          columnHeaders={value}
          onChange={(v: any) => {
            console.log({ v });
            onChange(name, { table_values: v });
          }}
        />
      );
    },
    unknown: ({ tagData: { value } }: any) => {
      return (
        <GriddedTextField
          label={name}
          value={value}
          onChange={(v: string) =>
            onChange(name, { value: v, _noMetaInfo: true })
          }
        />
      );
    },
  }[tagConfigData.type.toLowerCase()]({ tagData, tagConfigData });
};

export default DynamicFieldRenderer;
