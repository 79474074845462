export const DURATION_TOSTA = 5000;
export const DEFAULT_INSTALL_STATUS_VALUE = 10;
export const letterNumberRegXWithOutSpace = /^[0-9a-zA-Z_-]+$/;
export const letterNumberRegXWithSpace = /^[0-9a-zA-Z_-\s\.]*$/;
export const letterNumberTabluler = /^[0-9a-zA-Z_-|\s]*$/;
export const EXPIRE_LIMITED_DAYES_MAX = 90;
export const EXPIRE_LIMITED_DAYES = 30;
export const YELLOW_DAYS = 30;
export const RED_DAYS = 5;
export const YELLOW_DAYS_MESSAGE = `This device certificate is expiring within ${YELLOW_DAYS} days, please renew the certificate.`;
export const RED_DAYS_MESSAGE = `This device certificate is expiring within ${RED_DAYS} days, please renew the certificate.`;

export const CONFIG_TYPES = { LORIOT: "LORIOT" };
export const AUTH_TYPES = { X509: "X509" };

export const PAGES = {
  DEVICES: "devices",
  DEVICE_INSTALLATION: "device_installation",
};

export const installationStatusDropDownOptions = [
  { label: "Unknown", value: 10 },
  { label: "Installed", value: 30 },
  { label: "Uninstalled", value: 50 },
  { label: "Reviewed", value: 70 },
  { label: "Rejected", value: 90 },
  { label: "Operational", value: 110 },
  { label: "Maintenance", value: 130 },
];

export const EVENT_TYPES = [
  "DEVICE_CONNECTED",
  "DEVICE_DISCONNECTED",
  "DEVICE_CREATED",
  "DEVICE_DELETED",
  "IOT_HUB_ALERT",
];
