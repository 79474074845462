import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../CustomButton";
import Modal from "../../Modal";
import SubmitButton from "../../SubmitButton";
import ModalInput from "./ModalInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addModal: {
      flex: 1,
      display: "flex",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #454343",
      padding: "1em 0",
    },
    addNewButton: {
      color: "#0089FF",
      fontSize: "16px",
      margin: "1em 0 1em 0.5em",
      cursor: "pointer",
    },
  })
);

const AddModal: React.FC<any> = ({ label, columnHeaders, onClose, onAdd }) => {
  const classes = useStyles();
  const [record, setRecord] = useState(
    columnHeaders.map((ch: any) => ({ ...ch, value: "" }))
  );

  const updateField = (fieldId: string, value: string) => {
    const updatedRecord = record.map((field: any) => {
      if (field.id === fieldId) {
        return { ...field, value };
      }
      return field;
    });
    setRecord(updatedRecord);
  };

  return (
    <Modal title="Add Row" open={true} onClose={onClose}>
      <div className={classes.addModal}>
        {record.map(({ id, label: fieldLabel, value }: any) => {
          return (
            <ModalInput
              key={fieldLabel}
              label={label}
              field={fieldLabel}
              value={value}
              onChange={(value: string) => updateField(id, value)}
            />
          );
        })}
      </div>
      <div className={classes.buttonHolder}>
        <CustomButton variant="outlined-white" onClick={onClose}>
          Cancel
        </CustomButton>
        <SubmitButton
          onClick={() => {
            onAdd(record);
            onClose();
          }}
        >
          Save
        </SubmitButton>
      </div>
    </Modal>
  );
};

const AddRecord: React.FC<any> = ({ label, columnHeaders, onAdd }) => {
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();
  return (
    <>
      {showModal && (
        <AddModal
          label={label}
          columnHeaders={columnHeaders}
          onClose={() => setShowModal(false)}
          onAdd={onAdd}
        />
      )}
      <div
        role="button"
        className={classes.addNewButton}
        onClick={() => setShowModal(true)}
      >
        Add New Row
      </div>
    </>
  );
};

export default AddRecord;
