import React, { useState } from "react";

import { Typography } from "@material-ui/core";
import { Modal, CustomButton, LoadingIndicator } from "../";
import useApi from "../../../hooks/useApi";
import { useApplicationContext } from "../../../contexts/ApplicationContext";
import { IMenuItemProps } from "../DataTable/types";
import CommentInfoBox from "./CommentInfoBox";
import UpdateCommentCTA from "./UpdateCommentCTA";
import useStyles from "./Comments.styles";

const MAXIMUM_COMMENT_LENGTH = 2500;

const isPasted = (existingText: string, updatedText: string) => {
  const lengthDifference = Math.abs(existingText.length - updatedText.length);
  return lengthDifference > 1;
};

interface ICommentsProps extends IMenuItemProps {
  requestPage: string;
}

const Comments: React.FC<ICommentsProps> = ({
  requestPage,
  record,
  onDone,
}) => {
  const classes = useStyles();
  const { selectedApplication } = useApplicationContext();
  const [updatedComment, setUpdatedComment] = useState(null);
  const endPointURL = `/applications/${selectedApplication.application_id}/device-comment/${record.device_dlm_id}`;
  const { data, status } = useApi(endPointURL, {
    method: "GET",
    data: { request_page: requestPage },
    responseDataFormatter: (data: any) => {
      const { comment, created_by, created_on, updated_by, updated_on } =
        data?.device_comment || {};
      return {
        comment,
        updatedBy: updated_by || created_by,
        updatedOn: updated_on || created_on,
      };
    },
  });

  const inputValue =
    updatedComment === null ? data?.comment || "" : updatedComment;

  return (
    <Modal
      open={true}
      onClose={onDone}
      title={`Device Name: ${record.device_name}`}
    >
      <div className={classes.wrapper}>
        {status.pending && (
          <div style={{ height: "200px" }}>
            <LoadingIndicator />
          </div>
        )}

        {data ? (
          <>
            <CommentInfoBox
              updatedBy={data.updatedBy}
              updatedOn={data.updatedOn}
              commentLength={inputValue.length}
              maximumCommentLength={MAXIMUM_COMMENT_LENGTH}
            />
            <textarea
              placeholder="Enter comments"
              value={inputValue}
              onChange={(e: any) => {
                const text = e.target.value;
                if (isPasted(inputValue, text)) {
                  setUpdatedComment(text.substring(0, 2500));
                } else if (text.length <= 2500) {
                  setUpdatedComment(text);
                }
              }}
              style={{ width: "100%", height: "350px" }}
            />
          </>
        ) : (
          <Typography style={{ margin: "3em 0" }}>
            Unable to load comments. Please check permission.
          </Typography>
        )}
        <div className={classes.buttonHolder}>
          <CustomButton
            variant="outlined-white"
            onClick={() => onDone && onDone()}
          >
            Cancel
          </CustomButton>

          <UpdateCommentCTA
            requestPage={requestPage}
            currentComment={data?.comment}
            updatedComment={(updatedComment || "").trim()}
            onDone={onDone}
            endPointURL={endPointURL}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Comments;
