import React, { useState } from "react";
import HeaderNavigation from "./HeaderNavigation";
import { Grid } from "@material-ui/core";
import { GriddedTextField } from "../../UiComponents";
import CustomTextField from "../../UiComponents/TextField/TextField";
import useStyles from "./EditConfiguration.styles";
import { Tags, Properties, TabularDropdowns, Submit } from "./formParts";
import ButtonText from "../../UiComponents/ButtonText";
import { useSnackbar } from "notistack";

interface IEditConfigurationProps {
  onEditConfig: any;
  editItem: any;
}

const prepareFields = (configuration: any) => {
  return {
    configurationName: configuration.config_name,
    configurationDescription: configuration.config_desc,
  };
};

const EditConfiguration: React.FC<IEditConfigurationProps> = ({
  onEditConfig,
  editItem,
}) => {
  const [fields, setFields] = useState(prepareFields(editItem));
  const [tags, setTags] = useState(editItem.tags);
  const [properties, setProperties] = useState(editItem.properties);
  const [dropdown, setDropdown] = useState(editItem.dropdown);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // console.log(editItem);

  return (
    <>
      <HeaderNavigation onClick={onEditConfig} />

      <div className={classes.wrapper}>
        {/* TODO: Move this to basicFormFields */}
        <Grid container>
          <GriddedTextField
            label=" Select Application *"
            name="selectApplication"
            value={editItem.application_name}
            readOnly
          />
          <GriddedTextField
            name="config"
            label="Configuration Name"
            value={fields.configurationName}
            onChange={(value: string) =>
              setFields({ ...fields, configurationName: value })
            }
            readOnly
          />
          <GriddedTextField
            name="deviceType"
            label="Device Type *"
            value={editItem.device_type}
            readOnly
          />

          <GriddedTextField
            name="edgeType"
            label="Edge Type *"
            value={editItem.edge_type}
            readOnly
          />

          <GriddedTextField
            name="authType"
            label="Auth Type *"
            value={editItem.auth_type}
            readOnly
          />

          <Grid item xs={12}>
            <CustomTextField
              name="configDesc"
              label="Configuration Description *"
              value={fields.configurationDescription}
              onChange={(value: string) =>
                setFields({ ...fields, configurationDescription: value })
              }
            />
          </Grid>
          <Tags
            tags={tags}
            onTagChange={(tagName: any, tagData: any) => {
                const { type } = tagData;                    
                if (type === "Dropdown") {
                  const existingOptions = editItem.tags[tagName].options;
                  // if tagData.options starts with existing options and  appends data after "|"
                  if (tagData.options.startsWith(existingOptions + "|")) {
                    setTags({ ...tags, [tagName]: { ...tagData } });
                  } else if (tagData.options === existingOptions) {
                    //removes appended value along with "|"
                    if (existingOptions.endsWith("|")) {
                      const newOptions = existingOptions.slice(0, -1); // Remove the "|" symbol
                      setTags({
                        ...tags,
                        [tagName]: { ...tagData, options: newOptions },
                      });
                    } else {
                      // removes appended value but there's no "|" symbol to remove
                      setTags({ ...tags, [tagName]: tagData });
                    }
                  } else {
                    setTags(tags);
                    enqueueSnackbar(
                      "Cannot edit previous values. Append data only after adding | symbol.",
                      {
                        variant: "error",
                      }
                    );
                  }
                } else if (type === "Text") {
                  setTags({ ...tags, [tagName]: tagData });
                } else {
                  // Display error message for other types
                  enqueueSnackbar("Cannot edit previous values", {
                    variant: "error",
                  });
              }
            }}
          />
          <TabularDropdowns
            dropdowns={dropdown}
            onDropdownUpdate={(
              tableName: string,
              columnName: string,
              value: string
            ) => {
              const updatedTableOptions = {
                ...dropdown[tableName],
                [columnName]: value,
              };
              setDropdown({ ...dropdown, [tableName]: updatedTableOptions });
            }}
          />
          <ButtonText
            style={{ margin: "-1.2em 0 0 1em" }}
            label="Add new dropdown specifications"
            disabled
          />
          <Properties
            properties={properties}
            onPropertyChange={(propertyName: string, propertyValue: string) => {
              setProperties({ ...properties, [propertyName]: propertyValue });
            }}
          />
          <ButtonText
            style={{ margin: "-1.2em 0 0 1em" }}
            label="Add New Field"
            disabled
          />
          <Submit
            configData={{
              existingData: editItem,
              updatedData: { fields, tags, properties, dropdown },
            }}
            onDone={onEditConfig}
          />
        </Grid>
      </div>
    </>
  );
};

export default EditConfiguration;
