import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { display: "flex", alignItems: "center" },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    bgColorGrey: {
      color: "#4b4b4c !important",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);

const CustomCheckbox: React.FC<any> = ({
  name,
  label,
  checked,
  onChange,
  readOnly = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Checkbox
        name={name}
        className={readOnly ? classes.bgColorGrey : classes.bgColorBlue}
        readOnly={readOnly}
        checked={checked}
        onChange={() => !readOnly && onChange(!checked)}
        style={{ padding: "0px 5px" }}
      />
      <div
        className={classes.labels}
        style={readOnly ? { color: "#5f5f65" } : {}}
      >
        {label}
      </div>
    </div>
  );
};

export default CustomCheckbox;
