const validateTags = (tags: any) => {
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];
    if (tag.tag_type === "Tabular") {
      continue;
    }
    if (tag.tag_type === "Dropdown") {
      const options = tag.options.split("|");
      const tagValue = (tag.tag_value + "").trim();
      if (tagValue !== "" && !options.includes(tagValue)) {
        return `Tag value '${tagValue}' not present in dropdown options for '${tag.tag_name}'`;
      }
    }

    const isNumber = tag.tag_data_type === "number";
    if (isNumber) {
      if (!(tag.tag_value + "").trim()) {
        return `Tag value cannot be empty for 'number' tags. Please enter zero for '${tag.tag_name}'`;
      }
      if (isNaN(tag.tag_value)) {
        return `Invalid value given to tag '${tag.tag_name}'. Data type is number.`;
      }
    }
  }
  return "";
};

export default validateTags;
