const addOrUpdate = (array: any[], item: any, key: string) => {
  const itemIndex = array.findIndex((i) => i[key] === item[key]);
  if (itemIndex === -1) {
    return [...array, item];
  }
  return array.map((i, index) => (index === itemIndex ? item : i));
};

const addOrRemove = (array: any[], item: any, key?: string) => {
  const itemIndex = array.findIndex((i) =>
    key ? i[key] === item[key] : i === item
  );
  if (itemIndex === -1) {
    return [...array, item];
  }
  return array.filter((_, index) => itemIndex !== index);
};

export { addOrUpdate, addOrRemove };
