import { ThemeProvider } from "@material-ui/styles";
import theme from "./components/Theme";
import React, { useEffect, createContext, useReducer, useContext } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Users from "./components/Users/Users";
import Roles from "./components/Roles/Roles";
import Applications from "./components/Application/Applications";
import Dashboard from "./components/Dashboard/Dashboard";
import Devices from "./components/Devices/ShowDevices";
import Resources from "./components/Resources/Resources";
import DeviceConfig from "./components/Configurations/ShowConfigurations";
import ConnConfig from "./components/Configurations/ShowConnectionConfigurations";
import Events from "./components/Events";
import ValidateRoute from './components/ValidateRoute'
import Header from "./components/Header/Header";
import DeviceInstallation from "./components/DeviceInstallation";
import { reducer, initialState } from "./reducers/userReducer";
import { CssBaseline } from "@material-ui/core";
import Login from "./components/Login";
import { IAppStore } from "./user/AppStore";
import { useStore } from 'mobx-store-provider';

type ContextProps = {
  state: any;
  dispatch: any;
};

export const UserContext = createContext<Partial<ContextProps>>({});
const drawerWidth = 64;
const loggedin = "logged_in"
const Routing = () => {
  const history = useHistory();

  const { dispatch } = useContext(UserContext);
  const { user }: IAppStore = useStore();

  useEffect(() => {
    if (!user.isLoggedIn && !user.isLoggingIn) {
      window.sessionStorage.setItem(
        loggedin,
        "false"
      );
      user.handleLoginRedirect();
    }
    else {
      if (sessionStorage.getItem(loggedin) === "false") {
        window.sessionStorage.setItem(
          loggedin,
          "true"
        );
        history.push("/dashboard");
      }
    }
  }, [dispatch, history, user]);

  return (
    <>
      {user.isLoggedIn && (
        <HandleHeader />
      )}
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/roles">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Roles /></div></ValidateRoute>
        </Route>
        <Route exact path="/applications">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Applications /></div></ValidateRoute>
        </Route>
        <Route exact path="/dashboard" >
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Dashboard /></div></ValidateRoute>
        </Route>
        <Route exact path="/devices">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Devices /></div></ValidateRoute>
        </Route>

        <Route exact path="/resources">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Resources /></div></ValidateRoute>
        </Route>
        <Route path="/device-config">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><DeviceConfig /></div></ValidateRoute>
        </Route>
        <Route exact path="/conn-config">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><ConnConfig /></div></ValidateRoute>
        </Route>
        <Route exact path="/events">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Events /></div></ValidateRoute>
        </Route>
        <Route exact path="/users">
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Users /></div></ValidateRoute>
        </Route>
        <Route exact path='/device-management'>
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><Devices /></div></ValidateRoute>
        </Route>
        <Route exact path='/device-installation'>
          <ValidateRoute><div style={{ marginLeft: `${drawerWidth}px`, overflowX: 'hidden' }}><DeviceInstallation /></div></ValidateRoute>
        </Route>
      </Switch>

    </>
  );
};

const HandleHeader = () => {
  return (
    <>
      <React.Fragment>
        <ValidateRoute><Header /></ValidateRoute>
      </React.Fragment>
    </>
  )
}
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <UserContext.Provider value={{ state, dispatch }}>
          <BrowserRouter>
            <div><Routing /></div>
          </BrowserRouter>
        </UserContext.Provider>
      </CssBaseline>
    </ThemeProvider>
  );
}



export default App;