import React, { useState } from "react";
import { FormSection, DynamicFieldRenderer } from "../../../../UiComponents";
import { useInputContext } from "../../../../../contexts/InputContext";
import { Typography } from "@material-ui/core";

const getSelectedConfigData = (inputData: any) => {
  const {
    device_configs,
    lookup: { selectedConfigId },
  } = inputData;

  const selectedDeviceConfigData = device_configs.find(
    ({ device_config_id }: any) => device_config_id === selectedConfigId
  );
  return selectedDeviceConfigData || {};
};

const getTagConfigData = (selectedConfigData: any, tagData: any) => {
  const { tags: configTags } = selectedConfigData;
  const tagName = tagData.name;

  if (!configTags) {
    throw `The configuraiton selected for this device no longer exist`;
  }

  const tagConfigData = configTags[tagName];
  if (!tagConfigData) {
    throw `Tag '${tagName}' not found in selected configuration`;
  }
  if (tagData.type !== tagConfigData.type) {
    throw `Tag '${tagName}' details mismatch with configuration. Expected '${tagConfigData.type}' but found '${tagData.type}'`;
  }
  return tagConfigData;
};

const DeviceTags: React.FC<any> = ({
  tags,
  onFieldChange,
  onTagRenderingError,
}) => {
  const inputData = useInputContext();
  const selectedConfigData = getSelectedConfigData(inputData);
  const [tagRenderingError, setTagRenderingError] = useState("");

  return (
    <FormSection header="Tags">
      {tagRenderingError && (
        <div style={{ width: "100%", padding: "0 1.5em" }}>
          <Typography color="error">{`Error occured while rendering tags. ${tagRenderingError}`}</Typography>
        </div>
      )}
      {Object.keys(tags).map((tag: any) => {
        const tagData = { name: tag, ...tags[tag] };
        let tagConfigData;
        try {
          tagConfigData = getTagConfigData(selectedConfigData, tagData);
        } catch (error) {
          if (!tagRenderingError) {
            setTagRenderingError(error + "");
            onTagRenderingError(error);
          }
          return null;
        }

        return (
          <DynamicFieldRenderer
            key={tag}
            name={tag}
            tagConfigData={tagConfigData}
            /*TODO: Seems this check is no longer needes. As we are using DynamicFieldRenderer only for Tags now */
            tagData={typeof tagData === "string" ? { value: tagData } : tagData}
            onChange={onFieldChange}
          />
        );
      })}
    </FormSection>
  );
};

export default DeviceTags;
