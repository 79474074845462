import React, { useState, useEffect } from "react";
import AddNewApplication from "./AddNewApplication";
import EditApplication from "./EditApplication";
import {
  withStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import exportLogo from "../../assets/Export.png";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import { API } from "../../api/property";
import IconButton from "@material-ui/core/IconButton";
import { SnackbarProvider, useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import deleteLogo from "../../assets/Delete.png";
import CloseIcon from "@material-ui/icons/Close";
import "../Users/Pagination.css";
import { exportToExcel } from "../../utils/helper";
import ErrorDialog from "../../utils/ErrorDialog";
import { useStyles, CssTextField, DialogTitleStyles, DialogContent } from './applicationStyles'
import DeleteApplication from './DeleteApplication';
import ViewApplicationDetails from './ViewApplicationDetails';
import ExportApplication from './ExportApplication';
import ApplicationTableView from './ApplicationTableView';
import callXhrRequest from '../../utils/xhrRequestHandler';
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function CustomizedTables() {
  const { user } = useStore();
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isForm, setIsForm] = useState(false);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const cancelNewApplication = () => {
    setIsForm(false);
    setIsFormEdit(false);
    setReload(!reload);
  };
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menu, setMenu] = useState([] as any);
  const handleClick = (item: any) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    menu[item] = true;
    setMenu(menu);
    setAnchorEl(event.currentTarget);
  };
  const [editItem, setEditItem] = useState({} as any);
  const handleClose = (item: any) => () => {
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
  };
  const handleEdit = (item: any) => {
    setEditItem(item);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
    setViewForm(false);
    setIsFormEdit(true);
  };
  const [backDrop, setbackDrop] = useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };
  const [reload, setReload] = useState(false);
  const [appsData, setAppsData] = useState([] as any);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const updateError = () => {
    setError(false);
    setErrMsg("");
  };

  const handleDelete = async () => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const APPS_API = `${API["GETALLAPPLS"]}/${delapp}?pm_s_token=${pmST}&pm_s_id=${pmSId}`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace' :`true`
      },
    };
    await fetch(APPS_API, requestOptions)
      .then(function (response:any) {
        response.json().then((response: any) => {
        if (response && response.status === 200) {
          setOpenDialogDelete(false);
          enqueueSnackbar("Application Deleted", { variant: "success" });
          const menuClose = menu.map((m: any) => false);
          setMenu(menuClose);
          setViewForm(false);
          setReload(!reload);
        } 
        else if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function(data){
                sessionStorage.setItem(tokenKey, data);
                setbackDrop(false);
                handleDelete();
            }).catch(function(error){
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else {
          setError(true);
          setErrMsg(
            "Error occured while performing delete operation."
          );
        }
      })
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [delapp, setDelApp] = useState("");
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const dialogHandleDelete = (appId: any) => {
    setOpenDialogDelete(true);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setDelApp(appId);
  };
  const [exportpopup, setExportPopupOpen] = useState(false);
  const dialogHandleCloseDelete = () => {
    setOpenDialogDelete(false);
    setExportPopupOpen(false);
    setViewForm(false);
    setExportRadioBtn({
      application_name: true,
      snow_req_id: true,
      apex_id: true,
      lob: true,
    })
  };
  const [customisepopup, setCustomisePopup] = useState(false);
  const dialogHandleCloseCustomise = () => {

    setOpenDialogDelete(false);
    setExportPopupOpen(false);
    setViewForm(false);
    setCustomisePopup(false);
  };

  const [exportRadioBtn, setExportRadioBtn] = useState({
    application_name: true,
    snow_req_id: true,
    apex_id: true,
    lob: true,
  });
  const handleChange = (event: any) => {
    setExportRadioBtn({
      ...exportRadioBtn,
      [event.target.name]: event.target.checked,
    });
  };
  const handleExport = async () => {
    if (exportRadioBtn.application_name === false && exportRadioBtn.snow_req_id === false && exportRadioBtn.apex_id === false && exportRadioBtn.lob === false) {
      enqueueSnackbar("Please select at least one column to export.", { variant: "error" });
    } else {
      setTimeout(() => {
        setExportPopupOpen(false);
      }, 100);
      const expAppsChecked = [] as any;
      for (const [key, value] of Object.entries(exportRadioBtn)) {
        if (value) {
          expAppsChecked.push(key);
        }
      }
      setbackDrop(true);
      const exclData = [] as any;
      appsData.forEach((item: any) => {
        const row: any = {};

        if (exportRadioBtn.application_name) {
          row.ApplicationName = item.application_name;
        }
        if (exportRadioBtn.snow_req_id) {
          row.ServiceNowID = item.snow_req_id;
        }
        if (exportRadioBtn.apex_id) {
          row.ApexID = item.apex_id;
        }
        if (exportRadioBtn.lob) {
          row.LOB = item.metadata.lob;
        }
        if (Object.keys(row).length) {
          exclData.push(row);
        }
      });
      await exportToExcel(exclData, "Application", exclData.length);
      setbackDrop(false);
      enqueueSnackbar("Export initiated.", { variant: "success" });
      setTimeout(() => {
        setReload(!reload);
        setExportRadioBtn({
          application_name: true,
          snow_req_id: true,
          apex_id: true,
          lob: true,
        })
      }, 500);
    }
  };

  const [customiseColumn, setCustomiseColumn] = useState([
    {
      name: "Application Name",
      checked: true,
    },
    {
      name: "Service Now ID",
      checked: true,
    },
    {
      name: "Apex ID",
      checked: true,
    },
    {
      name: "LOB",
      checked: true,
    },
  ]);
  const [customiseColumnCheck, setCustomiseColumnCheck] = useState([
    {
      name: "Application Name",
      checked: true,
    },
    {
      name: "Service Now ID",
      checked: true,
    },
    {
      name: "Apex ID",
      checked: true,
    },
    {
      name: "LOB",
      checked: true,
    },
  ]);

  const onColumnCustomise = (index: any, event: any) => {
    const temp = [...customiseColumn]
    temp[index].checked = event.target.checked;
    setCustomiseColumn(temp);
  };

  const checkBoxStyles = (theme: any) => ({
    root: {
      color: "#FFFFFF",
      "&$checked": {
        color: "#0089FF",
      },
    },
    checked: {},
  });
  const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
  const handleCustomise = () => {
    let flag = false;
    customiseColumn.forEach((item: any) => {
      if (item.checked) {
        flag = true;
      }
    });

    if (flag) {
      setCustomisePopup(false)
      enqueueSnackbar("Columns Customised", { variant: "success" });
      const temp = [...customiseColumnCheck] as any;
      customiseColumn.forEach((item: any, index: number) => {
        temp[index].checked = item.checked
      })
      setCustomiseColumnCheck(temp);
    } else {
      enqueueSnackbar("Select at least one column", { variant: "error" });
    }
  };

  const [viewForm, setViewForm] = useState(false);
  const [viewItem, setViewItem] = useState([] as any);
  const [viewItemLevels, setViewItemLevels] = useState([] as any);

  const handleView = (item: any) => {
    setViewItem(item);
    setViewItemLevels(item.levels);
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl(null);
    setViewForm(true);
  };
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleCloseSearch = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setSearchActive(false);
  };
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    setbackDrop(true);
    interface sObj {
      pm_s_id: string;
    }
    interface tObj {
      pm_s_token: string;
    }
    let pmSId: sObj = {
      pm_s_id: "",
    };
    let pmST: tObj = {
      pm_s_token: "",
    };
    pmSId =
      sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
    pmST =
      sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
    const APPS_API = API["GETALLAPPLS"];
    fetch(`${APPS_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    })
      .then((response) => response.json())
      .then(function (response: any) {
        setbackDrop(false);
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function(data){
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              setReload(!reload)
  
            }).catch(function(error){
              console.log('error', error)
              user.triggerLogout();
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (
          response &&
          response.status === 200 &&
          typeof response.applications !== "undefined"
        ) {
          setAppsData(response && response.applications);
          setbackDrop(false);
        } 
        const menu = response && response.applications.map((m: any) => false);
        setMenu(menu);
      })
      .catch(function (error) {
        setbackDrop(false);
        console.log(error)
      });
  }, [reload, accessToken.access_token, API_KEY]);
  return (
    <React.Fragment>
      <div className={classes.gridRoot} style={{display: 'flex',alignItems: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
         {isForm?<BreadcumbComponent route='addApplication' func={cancelNewApplication}/>:isFormEdit?<BreadcumbComponent route='editApplication' func={cancelNewApplication}/>:<BreadcumbComponent route='application'/>}
      </div>
      <div className={isForm || isFormEdit ? classes.hidden : classes.gridRoot}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <List classes={{ root: classes.flexContainer }} dense={true}>
              <ListItem classes={{ root: classes.listItem }}>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Total Applications"
                  primaryTypographyProps={{
                    style: { display: "inline-block" },
                  }}
                  secondary={<span>{appsData && appsData.length}</span>}
                  secondaryTypographyProps={{
                    style: {
                      display: "inline-block",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginLeft: "5px",
                      fontSize: "16px",
                    },
                  }}
                />
              </ListItem>
              <ListItem
                classes={{ root: classes.listItem }}
                className={classes.listButtonWrap}
              >
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.listButton}
                  startIcon={<img alt="Export Logo" src={exportLogo}></img>}
                  onClick={() => setExportPopupOpen(true)}
                >
                  Export
                </Button>
              </ListItem>
              <ListItem
                classes={{ root: classes.listItem }}
                className={classes.listButtonWrap}
              >
                <Button
                  variant="text"
                  color="inherit"
                  className={classes.listButton}
                  startIcon={<ViewWeekOutlinedIcon style={{ color: "#7C7C94" }} />}
                  onClick={() => setCustomisePopup(true)}
                >
                  Customise Columns
                </Button>
              </ListItem>
              <ListItem
                classes={{ root: classes.listItem }}
                className={classes.listButtonWrap}
              >
                <ClickAwayListener onClickAway={handleCloseSearch}>
                  {searchActive || search !== "" ? (
                    <CssTextField
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: false,
                        className: classes.labels,
                      }}
                      autoComplete="off"
                      value={search}
                      onChange={handleChangeSearch}
                      id="filled-search"
                      type="search"
                      variant="filled"
                    />
                  ) : (
                    <Button
                      variant="text"
                      color="inherit"
                      className={classes.listButton}
                      startIcon={<SearchIcon style={{ color: "#7C7C94" }} />}
                      onClick={() => setSearchActive(true)}
                    >
                      Search
                    </Button>
                  )}
                </ClickAwayListener>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.rightSec}>
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => {
                  setIsForm(true);
                }}
              >
                Add New Application
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={!isForm ? classes.hidden : ""}>
        <AddNewApplication clickCancel={cancelNewApplication} />
      </div>
      <div className={!isFormEdit ? classes.hidden : ""}>
        <EditApplication
          editItem={editItem}
          clickCancel={cancelNewApplication}
        />
      </div>
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={isForm || isFormEdit ? classes.hidden : ""}>
        

          <ApplicationTableView
            customiseColumnCheck={customiseColumnCheck}
            appsData={appsData}
            search={search}
            handleClick={handleClick}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleEdit={handleEdit}
            handleView={handleView}
            dialogHandleDelete={dialogHandleDelete}
            menu={menu}

          />
       

        <DeleteApplication
          openDialogDelete={openDialogDelete}
          classes={classes}
          dialogHandleCloseDelete={dialogHandleCloseDelete}
          deleteLogo={deleteLogo}
          DialogTitle={DialogTitle}
          DialogActions={DialogActions}
          handleDelete={handleDelete}
        />

        <ExportApplication
          dialogHandleCloseDelete={dialogHandleCloseDelete}
          exportpopup={exportpopup}
          DialogTitle={DialogTitle}
          exportRadioBtn={exportRadioBtn}
          handleChange={handleChange}
          DialogActions={DialogActions}
          handleExport={handleExport}
        />

        <Dialog
          classes={{ paper: classes.dialogRoot }}
          onClose={dialogHandleCloseCustomise}
          aria-labelledby="customized-dialog-title"
          open={customisepopup}
        >
          <Grid container direction="row" alignItems="center">
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseCustomise}
            >
              Customise Columns
            </DialogTitle>
          </Grid>
          <DialogContent>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {customiseColumn.map((box, index) => (
                  <Grid item xs={12} style={{ marginLeft: "1em" }}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={customiseColumn[index].checked}
                          onChange={(event) =>
                            onColumnCustomise(index, event)
                          }
                          name={customiseColumn[index].name}
                        />
                      }
                      label={customiseColumn[index].name}
                    />
                  </Grid>
                ))}
              </FormGroup>
            </FormControl>
          </DialogContent>
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseCustomise}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleCustomise}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <ViewApplicationDetails
          classes={classes}
          DialogTitle={DialogTitle}
          DialogActions={DialogActions}
          dialogHandleCloseDelete={dialogHandleCloseDelete}
          viewForm={viewForm}
          viewItem={viewItem}
          viewItemLevels={viewItemLevels}
          dialogHandleDelete={dialogHandleDelete}
          handleEdit={handleEdit}
        />
      </div>
      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </React.Fragment>
  );
}

export default function Applications() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <CustomizedTables />
    </SnackbarProvider>
  );
}
