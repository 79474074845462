import React, { FC, useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  WithStyles,
  FormControlLabel,
  ListItemText,
  ListItem,
  List,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ClearIcon from "@material-ui/icons/Clear";
import { useSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import callXhrRequest from "../../../../../utils/xhrRequestHandler";
import {
  arrayOfErrorsRefresh,
  arrayOfErrorsLogout,
} from "../../../../../utils/helper";
import { useStore } from "mobx-store-provider";

import Checkbox from "@material-ui/core/Checkbox";
import { DEFAULT_INSTALL_STATUS_VALUE } from "../../../../../utils/constant";
import { API } from "../../../../../api/property";
import BreadcumbComponent from "../../../../../utils/breadcurmb";
import validateTags from "../../validateTags";

const border_style = "1px solid #464659";
const border_style2 = "1px solid rgba(235,235,245,0.35)";
const border_box = "border-box";
const alt_label_cover = "red !important";
const font = "Avenir Next";
const app_lvl_field = "Application Level *";
const cloud_field = "IoT Hub *";
const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#2B2B36",
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: border_style,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      color: "#FCFCFC",

      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
    },
    subtitle: {
      color: "#373746",
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: "23px",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    labels1: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
      marginTop: "1em",
      marginBottom: "1em",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0.5em 24px",
      backgroundColor: "#2B2B36",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: border_style,
      borderRadius: "2px",
      boxSizing: border_box,
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    menuButtonRoot: {
      padding: "0px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: border_style2,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNDevice: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
      marginLeft: "1em",
    },

    vp: {
      backgroundColor: "#2B2B36",
      border: border_style,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    buttonText: {
      color: "#0089FF",
      margin: "1em",
      textTransform: "none",
      fontSize: "14px",
    },
    addNewFieldText: {
      color: "#0089FF",
      marginTop: "1em",
      marginBottom: "1em",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "19px",
    },
    helperfield: {
      color: "#FCFCFC",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px",
    },
    textfield1: {
      color: "#FCFCFC",
      border: `1px solid #464659`,
      boxSizing: border_box,
      borderRadius: "2px",
      backgroundColor: "#2B2B36",
    },
    dropButton: {
      textTransform: "none",
      fontSize: "16px",
      margin: "0px",
      border: "1px solid #6E6E79",
      boxSizing: border_box,
      borderRadius: "4px",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },

    items: {
      color: "#FCFCFC",
      marginLeft: "2em",
      marginRight: "2em",
      marginTop: "1em",
      marginBottom: "1em",
    },
    dividerColor: {
      background: "#464659",
    },
    space2TopnBottom: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    stepperRoot: {
      width: "50%",
      margin: "0 auto",
    },
    stepname: {
      color: "#FFF",
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    stepactive: {
      color: "#FFF",
    },
    steplabel: {
      color: "#FFF",
      "& span": {
        color: "darkgray !important",
      },
      "& .active": {
        color: "green !important",
      },
    },
    step: {
      "&$completed": {
        color: "#0089ff",
      },
      "&$active": {
        color: "cornflowerblue",
      },
      "&$disabled": {
        color: "red",
      },
    },
    alternativeLabel: {
      color: "yellow !important",
      "&$active": {
        color: alt_label_cover,
      },
    },
    active: {
      color: "cornflowerblue !important",
    },
    completed: {
      color: "#0089ff !important",
    },
    disabled: {
      color: "red",
    },
    labelContainer: {
      color: alt_label_cover,
      "&$alternativeLabel": {
        marginTop: 0,
        color: "orange !important",
      },
      "&$active": {
        color: alt_label_cover,
      },
      "&$completed": {
        color: "green !important",
      },
    },
    hidden: {
      display: "none",
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    appTitle: {
      width: "100%",
    },
    tableWrapper: {
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    textField: {
      width: "16ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    margin: {
      margin: theme.spacing(1),
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    checkboxLabel: {
      fontFamily: font,
      fontSize: "16px",
      lineHeight: "28px",
      color: "#FFFFFF",
    },
    checkboxRoot: {
      marginTop: "2em",
      marginBottom: "1em",
      maxWidth: "18em",
    },
    gridVar: {
      fontFamily: font,
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginTop: "10px",
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "10px",
    },
    gridVal: {
      fontFamily: font,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
      marginTop: "10px",
      marginLeft: "20px",
      marginBottom: "10px",
      marginRight: "20px",
    },
    topGrid: {
      height: "91px",
      marginLeft: "5px",
      marginRight: "32px",
      marginTop: "1em",
      marginBottom: "1em",
      background: "#2B2B36",
      borderRadius: "4px",
    },
    topGrid1: {
      marginTop: "1em",
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingBottom: "30px",
      background: "#2B2B36",
      borderRadius: "4px",
    },
  })
);

const CssTextFieldVariable = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: border_style,
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: border_style,
      borderRadius: "2px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

interface ComponentProps {
  onEditDevice: any;
  editItem: any;
  editApp: any;
  editDlmId: any;
}

const getMergedTagOptions = (
  deviceDetails: any,
  applicationConfigsLookup: any,
  tagName: string
) => {
  const lookupConfig = applicationConfigsLookup.find(
    ({ device_config_id }: any) =>
      device_config_id === deviceDetails.device_config_id
  );
  const updatedOptionsFromConfigurationData = lookupConfig
    ? lookupConfig.tags[tagName].options
    : "";
  const currentlySelectedValue =
    deviceDetails.device_config.tags[tagName].value;
  // //Adding guard as tag might get deleted
  // const updatedOptionsFromConfigurationData = lookupConfig
  //   ? lookupConfig.tags[tagName]?.options || ""
  //   : "";
  // const currentlySelectedValue =
  //   deviceDetails.device_config.tags[tagName].value;
  const mergedTagOptions = new Set([
    ...updatedOptionsFromConfigurationData.split("|"),
    currentlySelectedValue,
  ]);

  return Array.from(mergedTagOptions).join("|");
};

const EditDeviceWrapper: FC<ComponentProps> = (props: ComponentProps) => {
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { user } = useStore();

  const classes = useStyles();

  const newFieldOptions = [
    {
      value: "Text",
      label: "Text",
    },
  ];
  const [hasTagRenderingError, setHasTagRenderingError] = useState(false);
  const [configs, setConfigs] = React.useState([] as any);
  const [connConfigs, setConnConfigs] = React.useState([] as any);
  const [reload, setReload] = React.useState(false);

  const [dropdown, setDropdown] = React.useState({
    tag: {
      initial: "initial",
    },
  });

  const [connConfig, setConnConfig] = React.useState(
    props.editItem.conn_config as any
  );
  const [connRequestPayload, setConnRequestPayload] = React.useState(
    props.editItem.conn_request_payload as any
  );
  /* const handleChangeConnConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConnConfig(event.target.value);
  }; */

  const [checked, setChecked] = React.useState(
    (props.editItem.conn_config_id ? true : false) as any
  );
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
  };

  const [tags1, setTags1] = React.useState([] as any);
  const handleChangeTags = (tagName: any, event: any) => {
    const temp = [...tags1];
    temp.forEach((tag: any) => {
      if (tag.tag_name === tagName) {
        tag.tag_value = event.target.value;
      }
    });
    setTags1(temp);
  };

  const [properties, setProperties] = React.useState([] as any);
  const handleChangeProperties = (propName: any, event: any) => {
    const temp = [...properties];
    temp.forEach((prop: any) => {
      if (prop.prop_name === propName) {
        prop.prop_value = event.target.value;
      }
    });
    setProperties(temp);
  };

  const deviceID = {
    fieldName: "Device ID *",
    fieldType: "Text",
    value: props.editItem.device_id,
    options: [],
  };
  const deviceName = {
    fieldName: "Device  Name *",
    fieldType: "Text",
    value: props.editItem.device_name,
    options: [],
  };

  const serial = {
    fieldName: "Serial Number *",
    fieldType: "Text",
    value: props.editItem.serial_num,
    options: [],
  };
  const [gateway, setGateway] = React.useState({} as any);
  const [app_level, setApp_level] = React.useState({} as any);

  const [application, setApplication] = React.useState([] as any);

  const [app, setApp] = React.useState("" as any);
  const [appName, setAppName] = React.useState("" as any);

  const [dataArray, setDataArray] = React.useState([] as any);
  const [rowIndex, setRowIndex] = React.useState(0 as any);

  const handleChangeAddNewRow = (index: any, event: any) => {
    let temp = [...dataArray];
    temp[index] = event.target.value;
    setDataArray(temp);
  };

  const handleSubmitNewRow = () => {
    let temp = [...tags1];
    for (const i of temp) {
      if (i.tag_name === selectedTag.tag_name) {
        i.table_values.push(dataArray);
      }
    }
    setTags1(temp);
    setDataArray([]);
  };

  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };

  const handleSubmitEditRow = () => {
    let temp = [...tags1];

    for (const i of temp) {
      if (i.tag_name === selectedTag.tag_name) {
        i.table_values[rowIndex] = dataArray;
      }
    }
    setTags1(temp);
    setDataArray([]);
  };

  const [selectedConfig, setSelectedConfig] = React.useState({} as any);
  const [selectedTag, setSelectedTag] = React.useState({
    tag_name: "initial",
    tag_value: "initial",
    tag_type: "initial",
    table_values: [],
  } as any);

  const [config, setConfig] = React.useState(
    props.editItem.device_config_id as any
  );

  const [tags, setTags] = React.useState([] as any);
  const [open, setOpen] = useState(false);
  const [openNewRow, setOpenNewRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);

  const [addFieldInput, setAddFieldInput] = useState([
    { fieldName: "", fieldType: "Text", value: "", options: [] },
  ]);

  const handleChangeAddNewFields = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...addFieldInput];
    values[index][event.target.name] = event.target.value;
    setAddFieldInput(values);
  };

  const handleSubmitNewFields = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const letterNumber = /^[0-9a-zA-Z_-\s]*$/;
    let flag = true;
    const array = [...addFieldInput];
    array.forEach((item: any) => {
      if (item.fieldName === "") {
        enqueueSnackbar("Please enter a field name", { variant: "error" });
        flag = false;
      } else if (
        !(letterNumber.test(item.fieldName) && item.fieldName.length < 50)
      ) {
        enqueueSnackbar(
          `Invalid field name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`,
          { variant: "error" }
        );
        flag = false;
      }
    });

    if (flag) {
      const tags1 = [...tags, ...addFieldInput];
      setTags(tags1);
      setOpen(false);
      setAddFieldInput([
        { fieldName: "", fieldType: "Text", value: "", options: [] },
      ]);
    }
  };

  const handleAddNewFields = () => {
    setAddFieldInput([
      ...addFieldInput,
      { fieldName: "", fieldType: "Text", value: "", options: [] },
    ]);
  };

  const handleDeleteNewFields = (index: number) => {
    const values = [...addFieldInput];
    values.splice(index, 1);
    setAddFieldInput(values);
  };

  const handleChange = (index: number, event: any) => {
    if (index != 0 && index != 3) {
      const temp = [...tags];
      temp[index].value = event.target.value;
      setTags(temp);
    }
  };
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = React.useState("");

  const validateField = (user_action: any) => {
    const letterNumber = /^[0-9a-zA-Z_-\s]*$/;

    let flag = true;
    if (app === "") {
      enqueueSnackbar("Please select Application", { variant: "error" });
      flag = false;
    }
    if (app_level.value === "") {
      enqueueSnackbar("Please select Application level", { variant: "error" });
      flag = false;
    }
    if (gateway.value === "") {
      enqueueSnackbar("Please select IoT Hub", { variant: "error" });
      flag = false;
    }
    if (connConfig === "" && checked) {
      enqueueSnackbar("Please select connection configuration", {
        variant: "error",
      });
      flag = false;
    } else if (
      !(letterNumber.test(tags[0].value) && tags[0].value.length <= 64)
    ) {
      enqueueSnackbar(
        "Invalid Device ID (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
      flag = false;
    } else if (
      !(letterNumber.test(tags[1].value) && tags[1].value.length <= 50)
    ) {
      enqueueSnackbar(
        "Invalid Device Name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
      flag = false;
    } else if (
      !(letterNumber.test(tags[2].value) && tags[2].value.length <= 50)
    ) {
      enqueueSnackbar(
        "Invalid Serial (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
      flag = false;
    } else {
      const tagValidationError = validateTags(tags1);
      if (tagValidationError) {
        enqueueSnackbar(tagValidationError, { variant: "error" });
        flag = false;
      }
    }
    /* for (let i = 5; i < tags.length; i++) {
      if (!(letterNumber1.test(tags[i].value) && tags[i].value.length < 50)) {
        enqueueSnackbar(`Invalid ${tags[i].fieldName} (only 0-9,A-Z,a-z,_,-,. allowed upto 50 characters)`, { variant: "error" });
        flag = false;
      }
    } */

    if (flag) {
      saveDeviceAction(user_action);
    }
  };

  const saveDeviceAction = (user_action: any) => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    const propps = [] as any;
    const propp = {} as any;
    properties.forEach((property: any) => {
      propp[`${property.prop_name}`] = property.prop_value;
    });
    propps.push(propp);

    const taggs = [] as any;
    const tagg = {} as any;
    tags1.forEach((tag: any) => {
      tagg[`${tag.tag_name}`] =
        tag.tag_type === "Tabular"
          ? {
              type: tag.tag_type,
              value: tag.tag_value,
              table_values: tag.table_values,
            }
          : tag.tag_type === "Dropdown"
          ? {
              type: tag.tag_type,
              value: tag.tag_value,
              options: tag.options,
              data_type: tag.tag_data_type,
            }
          : {
              type: tag.tag_type,
              value: tag.tag_value,
              data_type: tag.tag_data_type,
            };
    });
    taggs.push(tagg);

    const fields = {} as any;

    const hasInstallationStatus = tags[5]?.fieldName === "Installation Status";
    //we are mixing user defined fields with regular fields :(
    const tags_temp = tags.slice(hasInstallationStatus ? 6 : 5); //TODO: Refactor
    tags_temp.forEach((tag: any) => {
      fields[`${tag.fieldName}`] = tag.value;
    });
    const DEVICES_API = `${API["GETALLAPPLS"]}/${app}/devices/${props.editDlmId}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
      body:
        connConfig && checked
          ? JSON.stringify({
              pm_s_id: pmSessionId,
              pm_s_token: pmSessionToken,
              device: {
                application_level_id: tags[4].value,
                device_install_status_id: hasInstallationStatus
                  ? tags[5]?.value
                  : DEFAULT_INSTALL_STATUS_VALUE, //TODO: Refactor!
                device_id: tags[0].value,
                device_name: tags[1].value,
                serial_num: tags[2].value,
                device_config_id: config,
                device_config: {
                  config_name: selectedConfig.config_name,
                  config_desc: selectedConfig.config_type,
                  device_type: selectedConfig.device_type,
                  auth_type: selectedConfig.auth_type,
                  edge_type: selectedConfig.edge_type,
                  add_to_iot_hub: "y",
                  properties: propp,
                  tags: tagg,
                },
                cloud_gateway: tags[3].value,
                fields: fields,
                conn_config_id: connConfig.conn_config_id,
                conn_config: connConfig,
                conn_request_payload: connRequestPayload,
                user_action: user_action,
              },
            })
          : JSON.stringify({
              pm_s_id: pmSessionId,
              pm_s_token: pmSessionToken,
              device: {
                application_level_id: tags[4].value,
                device_install_status_id: hasInstallationStatus
                  ? tags[5]?.value
                  : DEFAULT_INSTALL_STATUS_VALUE, //TODO: Refactor!
                device_id: tags[0].value,
                device_name: tags[1].value,
                serial_num: tags[2].value,
                device_config_id: config,
                device_config: {
                  config_name: selectedConfig.config_name,
                  config_desc: selectedConfig.config_type,
                  device_type: selectedConfig.device_type,
                  auth_type: selectedConfig.auth_type,
                  edge_type: selectedConfig.edge_type,
                  add_to_iot_hub: "y",
                  properties: propp,
                  tags: tagg,
                },
                cloud_gateway: tags[3].value,
                fields: fields,
                user_action: user_action,
              },
            }),
    };

    setbackDrop(true);
    fetch(DEVICES_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
          if (
            response &&
            response.status === 401 &&
            response &&
            arrayOfErrorsRefresh.includes(response.message.toLowerCase())
          ) {
            try {
              const tokenKey = "sso_token";
              callXhrRequest()
                .then(function (data) {
                  sessionStorage.setItem(tokenKey, data);
                  saveDeviceAction(user_action);
                })
                .catch(function (error) {
                  user.triggerLogout();
                  console.log("error", error);
                });
            } catch (error) {
              console.log(error);
            }
            return;
          } else if (
            response &&
            response.status === 401 &&
            response &&
            arrayOfErrorsLogout.includes(response.message.toLowerCase())
          ) {
            try {
              user.triggerLogout();
            } catch (error) {
              console.log(error);
            }
            return;
          } else if (
            (response && response.status === 200) ||
            (response && response.status === 201)
          ) {
            setbackDrop(false);
            enqueueSnackbar("Device modification initiated", {
              variant: "success",
            });
            cancelDeviceAction();
          } else {
            enqueueSnackbar("Device modification failed", { variant: "error" });
            setbackDrop(false);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const cancelDeviceAction = () => {
    setTimeout(function () {
      props.onEditDevice();
      const temp = [...tags];
      temp.forEach((item: any) => {
        item.value = "";
      });
      temp.splice(4);
      setConfig("");
      setTags(temp);
    }, 500);
  };

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    const GET_DEVICES_INPUTS = API["GETDEVICESINPUTS"];
    Promise.all([
      fetch(
        GET_DEVICES_INPUTS +
          `?pm_s_id=${pmSessionId}&pm_s_token=${pmSessionToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Ocp-Apim-Subscription-Key": `${API_KEY}`,
            "Ocp-Apim-Trace": `true`,
          },
        }
      ),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response) {
        if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsRefresh.includes(response[0].message.toLowerCase())
        ) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                setReload(!reload);
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (
          response &&
          response[0].status === 401 &&
          response &&
          arrayOfErrorsLogout.includes(response[0].message.toLowerCase())
        ) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        setApplication(response && response[0].devices_input[0].applications);
        setApp(props.editApp);
        setSelectedConfig(props.editItem.device_config);
        setConfig(props.editItem.device_config_id);
        setConnConfig(props.editItem.conn_config);
        setConnRequestPayload(props.editItem.conn_request_payload);
        setChecked(props.editItem.conn_config_id ? true : false);

        let deviceConfigLookup: any;

        (response && response[0].devices_input[0].applications).forEach(
          (app: any) => {
            if (app.application_id == props.editApp) {
              app.device_configs.forEach((item: any) => {
                if (props.editItem.device_config_id === item.device_config_id) {
                  setDropdown(item.dropdown);
                }
              });
              setAppName(app.application_name);
              const levels = [] as any;
              if (app.application_level) {
                app.application_level.forEach((level: any) => {
                  levels.push({
                    label: level.level_name,
                    value: level.level_id,
                  });
                });
              }

              const iot_hubs = [] as any;
              if (app.iot_hubs) {
                app.iot_hubs.forEach((hub: any) => {
                  iot_hubs.push({
                    label: hub.iot_hub_name,
                    value: hub.iot_hub_name,
                  });
                });
              }
              setConfigs(app.device_configs);
              setConnConfigs(app.conn_configs);
              deviceConfigLookup = app.device_configs;
              const temp1 = [
                deviceID,
                deviceName,
                serial,
                {
                  fieldName: cloud_field,
                  fieldType: "Dropdown",
                  value: props.editItem.cloud_gateway,
                  options: iot_hubs,
                },
                {
                  fieldName: app_lvl_field,
                  fieldType: "Dropdown",
                  value: props.editItem.application_level_id,
                  options: levels,
                },
              ];

              if (app.install_status) {
                temp1.push({
                  fieldName: "Installation Status",
                  fieldType: "Dropdown",
                  value: props.editItem.device_install_status_id,
                  options: app.install_status.map(
                    ({
                      device_install_status_id: value,
                      device_installation_status: label,
                    }: any) => ({ value, label })
                  ),
                });
              }

              for (let i in props.editItem.fields) {
                temp1.push({
                  fieldName: i,
                  fieldType: "Text",
                  value: props.editItem.fields[i],
                  options: [],
                });
              }
              setTags(temp1);
            }
          }
        );

        //make use of closure.
        const getTagDataType = (tagName: any) => {
          const lookupConfig = deviceConfigLookup.find(
            ({ device_config_id }: any) => {
              return device_config_id === props.editItem.device_config_id;
            }
          ) || { tags: {} };

          return lookupConfig.tags[tagName].data_type;
        };

        const temp: any[] = [];
        try {
          for (const tag in props.editItem.device_config.tags) {
            if (props.editItem.device_config.tags[tag].type === "Tabular") {
              temp.push({
                tag_name: tag,
                tag_value: props.editItem.device_config.tags[tag].value,
                tag_type: props.editItem.device_config.tags[tag].type,
                table_values:
                  props.editItem.device_config.tags[tag].table_values,
              });
            } else if (props.editItem.device_config.tags[tag].type === "Text") {
              temp.push({
                tag_name: tag,
                tag_value: props.editItem.device_config.tags[tag].value,
                tag_type: props.editItem.device_config.tags[tag].type,
                tag_data_type: getTagDataType(tag),
              });
            } else if (
              props.editItem.device_config.tags[tag].type === "Dropdown"
            ) {
              temp.push({
                tag_name: tag,
                tag_value: props.editItem.device_config.tags[tag].value,
                tag_type: props.editItem.device_config.tags[tag].type,
                options: getMergedTagOptions(
                  props.editItem,
                  deviceConfigLookup,
                  tag
                ),
                tag_data_type: getTagDataType(tag),
              });
            }
          }
        } catch (error) {
          setHasTagRenderingError(true);
        }
        setTags1(temp);

        const temp2: any[] = [];
        for (const prop in props.editItem.device_config.properties) {
          temp2.push({
            prop_name: prop,
            prop_value: props.editItem.device_config.properties[prop],
          });
        }
        setProperties(temp2);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.editItem, API_KEY, reload]);

  return (
    <div className={classes.root}>
      <div
        className={classes.gridRoot}
        style={{ marginRight: "1.5em", marginBottom: "1.5em", width: "100%" }}
      >
        <Grid
          container
          direction="row"
          xs={12}
          sm={6}
          style={{ marginTop: "1em", marginBottom: "1em" }}
          alignItems="center"
        >
          <BreadcumbComponent route="editDevice" func={cancelDeviceAction} />
        </Grid>
      </div>

      <form className={classes.form} noValidate method="post">
        <div>
          <Typography className={classes.subtitle}>
            All fields marked (*) are mandatory
          </Typography>

          <Grid container>
            <Grid item xs={6} className={classes.spaceRight}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectApplication"
                >
                  Application
                </InputLabel>
                <div
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: "#2B2B36",
                    padding: "1em",
                    marginTop: "1em",
                  }}
                >
                  {appName}
                </div>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.spaceLeft}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="Device Configuration"
                >
                  Device Configuration
                </InputLabel>
                <div
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: "#2B2B36",
                    padding: "1em",
                    marginTop: "1em",
                  }}
                >
                  {selectedConfig.config_name}
                </div>
              </div>
            </Grid>
            {config ? (
              <Grid
                container
                xs={12}
                direction="row"
                justify="flex-start"
                spacing={5}
                alignItems="center"
                className={classes.topGrid}
              >
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Typography className={classes.gridVar}>
                      Device Type
                    </Typography>
                    <Typography className={classes.gridVal}>
                      {selectedConfig.device_type}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Typography className={classes.gridVar}>
                      Edge Type
                    </Typography>
                    <Typography className={classes.gridVal}>
                      {selectedConfig.edge_type}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Typography className={classes.gridVar}>
                      Authentication Type
                    </Typography>
                    <Typography className={classes.gridVal}>
                      {selectedConfig.auth_type}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )}

            {tags.map((tag: any, index: any) => {
              if (tag.fieldType === "Text") {
                return index != 0 ? (
                  <Grid
                    item
                    xs={6}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <CssTextField
                      fullWidth
                      name={tag.fieldName}
                      label={tag.fieldName}
                      type={tag.fieldType}
                      id={tag.fieldName}
                      className={classes.fieldWrapper}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => handleChange(index, event)}
                      value={tag.value}
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        classes={{ root: classes.labels }}
                        htmlFor="selectApplication"
                      >
                        {tag.fieldName}
                      </InputLabel>
                      <div
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#2B2B36",
                          padding: "1em",
                          marginTop: "1em",
                        }}
                      >
                        {tag.value}
                      </div>
                    </div>
                  </Grid>
                );
              } else if (tag.fieldType === "Dropdown") {
                return index != 3 ? (
                  <Grid
                    item
                    xs={6}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        classes={{ root: classes.labels }}
                        htmlFor={tag.fieldName}
                      >
                        {tag.fieldName}
                      </InputLabel>
                      <TextField
                        className={classes.textfield}
                        fullWidth
                        select
                        variant="outlined"
                        classes={{ root: classes.fieldRoot }}
                        inputProps={{ className: classes.field }}
                        value={tag.value}
                        id={tag.fieldName}
                        SelectProps={{
                          classes: {
                            iconOutlined: classes.icon,
                          },
                        }}
                        onChange={(event) => handleChange(index, event)}
                      >
                        {tag.options.map((option: any) => (
                          <option
                            className={classes.options}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    className={
                      index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
                    }
                  >
                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        classes={{ root: classes.labels }}
                        htmlFor="selectApplication"
                      >
                        {tag.fieldName}
                      </InputLabel>
                      <div
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#2B2B36",
                          padding: "1em",
                          marginTop: "1em",
                        }}
                      >
                        {tag.value}
                      </div>
                    </div>
                  </Grid>
                );
              } else return null;
            })}
            <Grid
              container
              xs={12}
              direction="row"
              alignItems="flex-end"
              spacing={3}
            >
              {file === "" ? (
                <div></div>
              ) : (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setFile("");
                    }}
                    classes={{ outlined: classes.dropButton }}
                  >
                    <Typography color="secondary">{file}</Typography>
                    <ClearIcon></ClearIcon>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Button onClick={() => setOpen(true)}>
            <Typography className={classes.addNewFieldText}>
              Add New Field
            </Typography>
          </Button>
          {config ? (
            <div>
              <Grid item xs={12}>
                <List
                  dense={true}
                  classes={{ root: classes.noSpace }}
                  className={classes.spaceTop}
                >
                  <ListItem classes={{ root: classes.noSpace }}>
                    <ListItemText
                      classes={{ root: classes.noSpace }}
                      primary="Tags"
                      primaryTypographyProps={{
                        style: {
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "16px",
                        },
                      }}
                      secondary="All fields marked (*) are mandatory"
                      secondaryTypographyProps={{
                        style: {
                          color: "#FCFCFC",
                          fontSize: "12px",
                          marginTop: "10px",
                        },
                      }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid
                container
                xs={12}
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.topGrid1}
              >
                {hasTagRenderingError && (
                  <div
                    style={{
                      color: "#db4848",
                      fontSize: "1.2em",
                      marginTop: ".5em",
                    }}
                  >
                    Mismatch between Device Config & Device Tags, please contact
                    support team for assistance.
                  </div>
                )}
                {tags1.map((tag: any, index: any) => {
                  if (tag.tag_type === "Text") {
                    return (
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "16px" }}
                        className={classes.spaceRight}
                      >
                        <InputLabel
                          style={{ marginTop: "1em" }}
                          classes={{ root: classes.labels }}
                          htmlFor={tag.tag_name}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            {tag.tag_name}
                            {/* <span> //Not displaying data type
                              {tag.tag_data_type && (
                                <span
                                  style={{
                                    fontSize: ".8em",
                                    marginLeft: ".4em",
                                  }}
                                >
                                  {`(${tag.tag_data_type})`}
                                </span>
                              )}
                            </span> */}
                            {/* <div>
                              <DeleteIcon onClick={() => { setTags1([...tags1].filter((item: any) => item !== tag)) }} style={{ marginLeft: '1em' }} fontSize='small'></DeleteIcon>
                            </div> */}
                          </Grid>
                        </InputLabel>
                        <CssTextFieldVariable
                          fullWidth
                          name={tag.tag_name}
                          type="Text"
                          id={tag.tag_name}
                          style={{ marginTop: "16px" }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.labels,
                          }}
                          value={tag.tag_value}
                          onChange={(event: any) =>
                            handleChangeTags(tag.tag_name, event)
                          }
                        />
                      </Grid>
                    );
                  } else if (tag.tag_type === "Dropdown") {
                    return (
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "16px" }}
                        className={classes.spaceRight}
                      >
                        <InputLabel
                          style={{ marginTop: "1em" }}
                          classes={{ root: classes.labels }}
                          htmlFor={tag.tag_name}
                        >
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            {tag.tag_name}
                            {/* <div>
                              <DeleteIcon onClick={() => { setTags1([...tags1].filter((item: any) => item !== tag)) }} style={{ marginLeft: '1em' }} fontSize='small'></DeleteIcon>
                            </div> */}
                          </Grid>
                        </InputLabel>
                        <CssTextFieldVariable
                          fullWidth
                          select
                          variant="outlined"
                          inputProps={{ className: classes.field }}
                          style={{ marginTop: "16px" }}
                          id="selectApplication"
                          SelectProps={{
                            classes: {
                              iconOutlined: classes.icon,
                            },
                          }}
                          value={tag.tag_value}
                          onChange={(event: any) =>
                            handleChangeTags(tag.tag_name, event)
                          }
                        >
                          {tag.options.split("|").map((option: any) => (
                            <option
                              className={classes.options}
                              key={option}
                              value={option}
                            >
                              {option}
                            </option>
                          ))}
                        </CssTextFieldVariable>
                      </Grid>
                    );
                  }
                })}
                {tags1.map((tag: any, index: any) => {
                  if (tag.tag_type === "Tabular") {
                    return (
                      <Grid
                        item
                        xs={12}
                        style={{ marginTop: "20px", marginBottom: "5px" }}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <Typography className={classes.labels}>
                            {tag.tag_name}
                          </Typography>
                          {/*                           <DeleteIcon onClick={() => { setTags1([...tags1].filter((item: any) => item !== tag)) }} style={{ marginLeft: '1em', color: '#FFFFFF' }} fontSize='small'></DeleteIcon>
                           */}{" "}
                        </Grid>

                        <TableContainer
                          component={Paper}
                          className={classes.tableWrapper}
                        >
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                {tag.tag_value
                                  .split("|")
                                  .map((value: any, index: any) => {
                                    return (
                                      <StyledTableCell>
                                        <Grid container direction="row">
                                          <Grid item>
                                            <Typography
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {value}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </StyledTableCell>
                                    );
                                  })}
                                <StyledTableCell style={{ width: "1em" }}>
                                  &nbsp;
                                </StyledTableCell>
                                <StyledTableCell style={{ width: "1em" }}>
                                  &nbsp;
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {tag.table_values.map(
                                (value: any, index: any) => {
                                  return (
                                    <StyledTableRow>
                                      {value.map((item: any, index: any) => {
                                        return (
                                          <StyledTableCell>
                                            {item}
                                          </StyledTableCell>
                                        );
                                      })}
                                      <StyledTableCell style={{ width: "1em" }}>
                                        <IconButton
                                          onClick={() => {
                                            setOpenEditRow(true);
                                            setSelectedTag(tag);
                                            setRowIndex(index);
                                            let array = [];
                                            for (const i of value) {
                                              array.push(i);
                                            }
                                            setDataArray(array);
                                          }}
                                          classes={{
                                            root: classes.menuButtonRoot,
                                          }}
                                        >
                                          <EditIcon color="secondary" />
                                        </IconButton>
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: "1em" }}>
                                        <IconButton
                                          onClick={() => {
                                            let temp = [...tags1];
                                            for (const i of temp) {
                                              if (i.tag_name === tag.tag_name) {
                                                i.table_values.splice(index, 1);
                                              }
                                            }
                                            setTags1(temp);
                                          }}
                                          classes={{
                                            root: classes.menuButtonRoot,
                                          }}
                                        >
                                          <DeleteIcon color="secondary" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          onClick={() => {
                            setOpenNewRow(true);
                            setSelectedTag(tag);
                            let array = [];
                            for (const i of tag.tag_value.split("|")) {
                              array.push("");
                            }
                            setDataArray(array);
                          }}
                        >
                          <Typography className={classes.addNewFieldText}>
                            Add New Row
                          </Typography>
                        </Button>
                      </Grid>
                    );
                  }
                })}
              </Grid>

              <Grid item xs={12}>
                <List
                  dense={true}
                  classes={{ root: classes.noSpace }}
                  className={classes.spaceTop}
                >
                  <ListItem classes={{ root: classes.noSpace }}>
                    <ListItemText
                      classes={{ root: classes.noSpace }}
                      primary="Properties"
                      primaryTypographyProps={{
                        style: {
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "16px",
                        },
                      }}
                      secondary="All fields marked (*) are mandatory"
                      secondaryTypographyProps={{
                        style: {
                          color: "#FCFCFC",
                          fontSize: "12px",
                          marginTop: "10px",
                        },
                      }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid
                container
                xs={12}
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.topGrid1}
              >
                {properties.map((prop: any, index: any) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      style={{ marginTop: "16px" }}
                      className={classes.spaceRight}
                    >
                      <InputLabel
                        style={{ marginTop: "1em" }}
                        classes={{ root: classes.labels }}
                        htmlFor={prop.prop_name}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          {prop.prop_name}
                          {/* <div>
                            <DeleteIcon
                              onClick={() => { setProperties([...properties].filter((item: any) => item !== prop)) }}
                              style={{ marginLeft: '1em' }} fontSize='small'></DeleteIcon>
                          </div> */}
                        </Grid>
                      </InputLabel>
                      <CssTextFieldVariable
                        fullWidth
                        name={prop.prop_name}
                        type="Text"
                        id={prop.prop_name}
                        style={{ marginTop: "16px" }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        value={prop.prop_value}
                        onChange={(event: any) =>
                          handleChangeProperties(prop.prop_name, event)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ) : (
            <div></div>
          )}

          <Dialog
            fullWidth
            classes={{ paper: classes.dialogRoot }}
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setOpen(false)}
            >
              Add New Field
            </DialogTitle>
            <DialogContent style={{ overflowX: "hidden", overflowY: "auto" }}>
              <form onSubmit={handleSubmitNewFields}>
                {addFieldInput.map((inputField, index) => (
                  <div>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                      justify="space-around"
                    >
                      <Grid item xs={4} className={classes.spaceLeft}>
                        <CssTextField
                          fullWidth
                          name="fieldName"
                          label="Field Name *"
                          type="fieldName"
                          id="fieldName"
                          className={classes.fieldWrapper}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.labels,
                          }}
                          onChange={(event) =>
                            handleChangeAddNewFields(index, event)
                          }
                          value={inputField.fieldName}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.spaceLeft}>
                        <div className={classes.fieldWrapper}>
                          <InputLabel
                            classes={{ root: classes.labels }}
                            htmlFor="fieldType"
                          >
                            Field Type *
                          </InputLabel>
                          <TextField
                            className={classes.textfield}
                            fullWidth
                            select
                            variant="outlined"
                            name="fieldType"
                            classes={{ root: classes.fieldRoot }}
                            inputProps={{ className: classes.field }}
                            value={inputField.fieldType}
                            id="fieldType"
                            SelectProps={{
                              classes: {
                                iconOutlined: classes.icon,
                              },
                            }}
                            onChange={(event) =>
                              handleChangeAddNewFields(index, event)
                            }
                          >
                            {newFieldOptions.map((option: any) => (
                              <option
                                className={classes.options}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <Button onClick={() => handleDeleteNewFields(index)}>
                          <DeleteIcon style={{ color: "#FFFFFF" }}></DeleteIcon>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ))}
                <Button onClick={() => handleAddNewFields()}>
                  <Typography className={classes.buttonText}>
                    Add New Field
                  </Typography>
                </Button>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                variant="outlined"
                className="dcBtDelete"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmitNewFields}
                variant="contained"
                className="dcBtEdit"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            classes={{ paper: classes.dialogRoot }}
            onClose={() => setOpenNewRow(false)}
            aria-labelledby="customized-dialog-title"
            open={openNewRow}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setOpenNewRow(false)}
            >
              Add New Row
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <form onSubmit={handleSubmitNewRow}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  justify="space-around"
                >
                  {selectedTag.tag_value
                    .split("|")
                    .map((value: any, index: any) => {
                      if (
                        dropdown[`${selectedTag.tag_name}`] &&
                        dropdown[`${selectedTag.tag_name}`][`${value}`]
                      ) {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <div className={classes.fieldWrapper}>
                              <InputLabel classes={{ root: classes.labels }}>
                                {value}
                              </InputLabel>
                              <TextField
                                className={classes.textfield}
                                fullWidth
                                select
                                variant="outlined"
                                classes={{ root: classes.fieldRoot }}
                                inputProps={{ className: classes.field }}
                                value={dataArray[index]}
                                id={value}
                                SelectProps={{
                                  classes: {
                                    iconOutlined: classes.icon,
                                  },
                                }}
                                onChange={(event) =>
                                  handleChangeAddNewRow(index, event)
                                }
                              >
                                {dropdown[`${selectedTag.tag_name}`][`${value}`]
                                  .split("|")
                                  .map((option: any) => (
                                    <option
                                      className={classes.options}
                                      key={option}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  ))}
                              </TextField>
                            </div>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <CssTextField
                              fullWidth
                              name={value}
                              label={value}
                              type={value}
                              id={value}
                              className={classes.fieldWrapper}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.labels,
                              }}
                              onChange={(event) =>
                                handleChangeAddNewRow(index, event)
                              }
                              value={dataArray[index]}
                            />
                          </Grid>
                        );
                      }

                      //else dropdown
                    })}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenNewRow(false)}
                variant="outlined"
                className="dcBtDelete"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSubmitNewRow();
                  setOpenNewRow(false);
                }}
                variant="contained"
                className="dcBtEdit"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            classes={{ paper: classes.dialogRoot }}
            onClose={() => setOpenEditRow(false)}
            aria-labelledby="customized-dialog-title"
            open={openEditRow}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setOpenEditRow(false)}
            >
              Edit Row
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <form onSubmit={handleSubmitEditRow}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  justify="space-around"
                >
                  {selectedTag.tag_value
                    .split("|")
                    .map((value: any, index: any) => {
                      if (
                        dropdown[`${selectedTag.tag_name}`] &&
                        dropdown[`${selectedTag.tag_name}`][`${value}`]
                      ) {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <div className={classes.fieldWrapper}>
                              <InputLabel classes={{ root: classes.labels }}>
                                {value}
                              </InputLabel>
                              <TextField
                                className={classes.textfield}
                                fullWidth
                                select
                                variant="outlined"
                                classes={{ root: classes.fieldRoot }}
                                inputProps={{ className: classes.field }}
                                value={dataArray[index]}
                                id={value}
                                SelectProps={{
                                  classes: {
                                    iconOutlined: classes.icon,
                                  },
                                }}
                                onChange={(event) =>
                                  handleChangeAddNewRow(index, event)
                                }
                              >
                                {dropdown[`${selectedTag.tag_name}`][`${value}`]
                                  .split("|")
                                  .map((option: any) => (
                                    <option
                                      className={classes.options}
                                      key={option}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  ))}
                              </TextField>
                            </div>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item xs={4} className={classes.spaceLeft}>
                            <CssTextField
                              fullWidth
                              name={value}
                              label={value}
                              type={value}
                              id={value}
                              className={classes.fieldWrapper}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.labels,
                              }}
                              onChange={(event) =>
                                handleChangeAddNewRow(index, event)
                              }
                              value={dataArray[index]}
                            />
                          </Grid>
                        );
                      }

                      //else dropdown
                    })}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenEditRow(false)}
                variant="outlined"
                className="dcBtDelete"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSubmitEditRow();
                  setOpenEditRow(false);
                }}
                variant="contained"
                className="dcBtEdit"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <div className={classes.fieldWrapper}>
            <Grid container direction="column">
              <FormControlLabel
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox
                    checked={checked}
                    /* onChange={handleChangeChecked} */
                    name="checked"
                    color="primary"
                    className={classes.bgColorBlue}
                  />
                }
                label="Add a Connection Configuration"
              />

              {checked ? (
                <Grid item xs={6} className={classes.spaceRight}>
                  <div className={classes.fieldWrapper}>
                    <InputLabel
                      classes={{ root: classes.labels }}
                      htmlFor="selectApplication"
                    >
                      Connection Configuration
                    </InputLabel>
                    <div
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: "#2B2B36",
                        padding: "1em",
                        marginTop: "1em",
                      }}
                    >
                      {connConfig ? connConfig.config_name : ""}
                    </div>
                  </div>
                </Grid>
              ) : (
                <div></div>
              )}
            </Grid>
          </div>
        </div>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={cancelDeviceAction}
          >
            Cancel
          </Button>
          <div>
            {props.editItem.device_status.current_status ===
            "IOT_HUB_REGISTRATION_FAILED" ? (
              <Button
                variant="contained"
                className={classes.btnAddNDevice}
                onClick={() =>
                  validateField("UPDATE_AND_RETRY_IOT_HUB_REGISTRATION")
                }
              >
                Retry Device Addition
              </Button>
            ) : (
              <div></div>
            )}

            <Button
              variant="contained"
              className={classes.btnAddNDevice}
              onClick={() =>
                !hasTagRenderingError && validateField("UPDATE_DEVICE")
              }
              disabled={hasTagRenderingError}
            >
              Save Device
            </Button>
          </div>
        </div>
        <Backdrop
          className={classes.backdrop}
          open={backDrop}
          onClick={backDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </div>
  );
};

interface ChildComponentProps {
  onEditDevice: any;
  editItem: any;
  editDlmId: any;
  editApp: any;
}

export default function LegacyEditDevice({
  editItem,
  onEditDevice,
  editDlmId,
  editApp,
}: ChildComponentProps) {
  return (
    <EditDeviceWrapper
      editItem={editItem}
      onEditDevice={onEditDevice}
      editApp={editApp}
      editDlmId={editDlmId}
    />
  );
}
