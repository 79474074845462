import React from "react";
import TextField from "../../TextField";
import { DropDown } from "../../DropDown";
import { useInputContext } from "../../../../contexts/InputContext";

const getDropDownOptions = (inputs: any, label: string, fieldName: string) => {
  const selectedConfig = inputs.device_configs.find(
    ({ device_config_id }: any) => {
      return device_config_id === +inputs.lookup.selectedConfigId;
    }
  );
  let dropdownOptionString = "";
  if (selectedConfig && selectedConfig.dropdown[label]) {
    dropdownOptionString = selectedConfig.dropdown[label][fieldName] || "";
  }

  return dropdownOptionString
    ? dropdownOptionString.split("|").map((op) => ({ label: op, value: op }))
    : null;
};

const ModalInput: React.FC<any> = ({ label, field, value, onChange }) => {
  const inputs = useInputContext();
  const dropDownOptions = getDropDownOptions(inputs, label, field);
  if (dropDownOptions) {
    return (
      <DropDown
        key={field}
        label={field}
        options={dropDownOptions}
        value={value}
        onChange={onChange}
      />
    );
  }
  return (
    <TextField label={field} key={field} value={value} onChange={onChange} />
  );
};

export default ModalInput;
