import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import useApi from "../../../../hooks/useApi";
import { LoadingIndicator } from "../../../UiComponents";
import { InputContextProvider } from "../../../../contexts/InputContext";
import {
  BasicDeviceFields,
  CustomDeviceFields,
  DeviceTags,
  DeviceProperties,
  DeviceConnectionConfigurationFields,
  Submit,
} from "./formParts";
import HeaderNavigation from "./HeaderNavigation";
import useStyles from "./ReplaceDevice.styles";
import { useHistory, useParams } from "react-router";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
// import mockApi from "./developerTools/mockApi";
// import loriotData from "./developerTools/loriotData";

const formatFields = (deviceDetails: any) => {
  let formattedFields = {
    ...deviceDetails,
    device_id_new: "",
  };
  if (deviceDetails.conn_request_payload) {
    formattedFields = {
      ...formattedFields,
      conn_request_payload: {
        //Hide all values except title
        ...formattedFields.conn_request_payload,
        title: "",
        appeui: "",
        appkey: "",
        description: "",
        deveui: "",
      },
    };
  }
  return formattedFields;
};

const isEmpty = (obj: any) => Object.keys(obj).length === 0;

// //TODO: REMOVE. USED FOR TESTING
// const injectLoriotConfig = (deviceDetails: any) => {
//   if (deviceDetails.device_id === "nt1671175340152") {
//     return {
//       ...deviceDetails,
//       ...loriotData,
//     };
//   }
//   return deviceDetails;
// };

const ReplaceDevice: React.FC<any> = () => {
  const { deviceDlmId }: any = useParams();
  const history = useHistory();
  const { selectedApplication } = useApplicationContext();
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>({});
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);

  const gotoDeviceManagement = () => history.push("/");

  const { data: deviceDetails } = useApi(
    `/applications/${selectedApplication.application_id}/devices/${deviceDlmId}`,
    {
      method: "GET",
      responseDataFormatter: (data: any) => {
        return data.device;
      },
      // mock: { fetcher: mockApi },
    }
  );

  useEffect(() => {
    deviceDetails && setFormValues(formatFields(deviceDetails));
  }, [deviceDetails]);

  if (!deviceDetails || isEmpty(formValues)) {
    return <LoadingIndicator />;
  }

  return (
    <InputContextProvider
      applicationId={+selectedApplication.application_id}
      lookup={{ selectedConfigId: formValues.device_config_id }}
    >
      <HeaderNavigation onClick={gotoDeviceManagement} />
      <div className={classes.wrapper}>
        <Grid container>
          <BasicDeviceFields
            deviceDetails={formValues}
            onFieldChange={(field: string, value: string) => {
              if (field === "device_id_new" && formValues.conn_config) {
                setFormValues({
                  ...formValues,
                  [field]: value,
                  conn_request_payload: {
                    ...formValues.conn_request_payload,
                    title: value,
                    description: value,
                    deveui: value,
                  },
                });
              } else {
                setFormValues({ ...formValues, [field]: value });
              }
            }}
          />
          {deviceDetails.fields && (
            <CustomDeviceFields
              fields={formValues.fields}
              onFieldChange={(field: string, value: string) =>
                setFormValues({
                  ...formValues,
                  fields: { ...formValues.fields, [field]: value },
                })
              }
            />
          )}
        </Grid>
        {deviceDetails.device_config?.tags && (
          <DeviceTags
            tags={formValues.device_config.tags}
            onTagRenderingError={() => setDisableSubmitButton(true)}
            onFieldChange={(tagName: string, changedFields: any) => {
              setFormValues({
                //TODO: too complex? useReducer
                ...formValues,
                device_config: {
                  ...formValues.device_config,
                  tags: {
                    ...formValues.device_config.tags,
                    [tagName]: changedFields._noMetaInfo
                      ? changedFields.value
                      : {
                          ...formValues.device_config.tags[tagName],
                          ...changedFields, //if its a tabular tag, 'value' field holds the column names. So let renderer decide what to change
                        },
                  },
                },
              });
            }}
          />
        )}
        {deviceDetails.device_config?.properties && (
          <DeviceProperties
            properties={formValues.device_config.properties}
            onPropertyChange={(propertyName: string, value: any) => {
              setFormValues({
                ...formValues,
                device_config: {
                  ...formValues.device_config,
                  properties: {
                    ...formValues.device_config.properties,
                    [propertyName]: value,
                  },
                },
              });
            }}
          />
        )}
        {formValues.conn_config && (
          <DeviceConnectionConfigurationFields
            loriotData={{
              conn_config: formValues.conn_config,
              conn_request_payload: formValues.conn_request_payload,
            }}
            onFieldChange={(field: string, value: string) =>
              setFormValues({
                ...formValues,
                conn_request_payload: {
                  ...formValues.conn_request_payload,
                  [field]: value,
                },
              })
            }
          />
        )}
        <Submit
          application={selectedApplication.application_id}
          onDone={gotoDeviceManagement}
          formValues={formValues}
          disabled={disableSubmitButton}
        />
      </div>
    </InputContextProvider>
  );
};

export default ReplaceDevice;
