import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "2em",
      color: "white",
      flexDirection: "column",
    },
    tagWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    tagHeaderWrapper: {
      display: "flex",
      justifyContent: "space-between",
      margin: "0 1.5em -0.5em 1.5em",
    },
  })
);

export default useStyles;
