import React, { useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "./DeviceManagement.styles";

const EXPIRE_LIMITED_DAYES_MAX = 90;

const ExpiringDeviceNotification: React.FC<any> = ({
  devicesExpireCount,
  onClick,
}) => {
  const [interacted, setInteracted] = useState(false);
  const classes = useStyles();
  if (!devicesExpireCount) {
    return null;
  }

  if (interacted) {
    return null;
  }

  return (
    <div className={classes.expireBlock}>
      <p>
        Hurry! {devicesExpireCount} device's certificate has expiry in{" "}
        {EXPIRE_LIMITED_DAYES_MAX} days!
      </p>
      <Button
        variant="contained"
        color="inherit"
        size="small"
        className={classes.expireBtn}
        onClick={() => {
          onClick();
          setInteracted(true);
        }}
      >
        Show
      </Button>

      <IconButton
        edge="start"
        color="inherit"
        onClick={() => setInteracted(true)}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default ExpiringDeviceNotification;
