import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  CustomButton,
  LoadingIndicator,
  SubmitButton,
} from "../../../../UiComponents";
import { letterNumberRegXWithSpace } from "../../../../../utils/constant";
import useApi from "../../../../../hooks/useApi";
import useStyles from "./Submit.styles";

const MAX_TEXT_LIMIT = 50;

const validateDropdownTag = (tagName: string, tagData: any) => {
  const optionsArray: string[] = tagData.options.split("|");

  if (tagData.data_type === "number") {
    if (optionsArray.some((op) => !op)) {
      return `Invalid options given to tag '${tagName}'. Options should not be empty`;
    }
    if (optionsArray.some((op: string) => isNaN(+op))) {
      return `Invalid options given to tag '${tagName}'. Data type is number.`;
    }
  }
  return "";
};

const validateTextTag = (tagName: string, tagData: any) => {
  const isNumber = tagData.data_type === "number";
 
  if (isNumber) {
    if (!tagData.value) {
      return `Tag default value cannot be empty for 'number' tags. Please enter zero for ${tagName}`;
    }
    if (isNaN(tagData.value)) {
      return `Invalid value given to tag '${tagName}'. Data type is number.`;
    }
  }
  return "";
};

const validateTags = (tags: any) => {
  for (const tagName in tags) {
    const tagData = tags[tagName];
    if (tagData.type === "Dropdown") {
      const validationResult = validateDropdownTag(tagName, tagData);
      if (validationResult) {
        return validationResult;
      }
    } else if (tagData.type === "Text") {
      const validationResult = validateTextTag(tagName, tagData);
      if (validationResult) {
        return validationResult;
      }
    }
  }
  return "";
};

const validateProperties = (properties: any) => {
  for (const property in properties) {
    const propertyValue = properties[property] || "";
    if (
      !letterNumberRegXWithSpace.test(propertyValue) ||
      propertyValue.length > MAX_TEXT_LIMIT
    ) {
      return `Invalid value for property ${property} (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`;
    }
  }
  return "";
};

const validateData = (configData: any) => {
  const { config_desc, tags, properties } = configData;
  if (config_desc === "") {
    return "Please enter Configuration Description.";
  }
  if (
    !(letterNumberRegXWithSpace.test(config_desc) && config_desc?.length < 50)
  ) {
    return "Invalid Configuration Description (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)";
  }

  const tagValidationError = validateTags(tags);
  if (tagValidationError) {
    return tagValidationError;
  }
  return validateProperties(properties);
};

const prepareTags = (tags: any) => {
  const updatedTags = {};
  for (const tag in tags) {
    const { updatedVisibility, ...rest } = tags[tag];
    updatedTags[tag] = {
      ...rest,
      ...(updatedVisibility !== undefined
        ? { is_visible_as_column: updatedVisibility }
        : {}),
    };
  }

  return updatedTags;
};

const mapData = (configData: any) => {
  let mappedData: any = {
    device_config: {
      ...configData.existingData,
      tags: prepareTags(configData.updatedData.tags),
      properties: configData.updatedData.properties,
      config_name: configData.updatedData.fields.configurationName,
      config_desc: configData.updatedData.fields.configurationDescription,
      dropdown: configData.updatedData.dropdown,
    },
  };

  return mappedData;
};

const Submit: React.FC<any> = ({ configData, onDone }) => {
  const classes = useStyles();
  const { application_id, device_config_id } = configData.existingData;

  const { enqueueSnackbar } = useSnackbar();
  const {
    data: saveApiResponse,
    status: saveStatus,
    trigger: submitData,
  } = useApi(
    `/applications/${application_id}/device-configs/${device_config_id}`,
    {
      method: "PUT",
      deferred: true,
      includePMSCredentialsInRequestBody: true,
      // mock: { fetcher: () => ({ message: "success" }) },
    }
  );

  useEffect(() => {
    if ((saveApiResponse?.message || "").toLowerCase() === "success") {
      enqueueSnackbar("Configuration Changed", { variant: "success" });
      onDone();
    }
    if (saveStatus.error) {
      enqueueSnackbar("An error occured while updating configuration", {
        variant: "error",
      });
    }
  }, [saveApiResponse, saveStatus.error]);

  return (
    <div className={classes.buttonWrapper}>
      {saveStatus.pending && <LoadingIndicator />}
      <CustomButton variant="outlined-white" onClick={onDone}>
        Cancel
      </CustomButton>
      <SubmitButton
        disabled={saveStatus.pending}
        onClick={() => {
          const data = mapData(configData);
          const error = validateData(data.device_config);
          if (error) {
            return enqueueSnackbar(error, { variant: "error" });
          }
          submitData(data);
        }}
      >
        Save Configuration
      </SubmitButton>
    </div>
  );
};

export default Submit;
