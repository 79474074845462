import validateTags from "../../validateTags";

const hasSubsetOfKeys = (importedTags: any, configTags: any) => {
  const configTagKeys = Object.keys(configTags);
  const importedTagKeys = Object.keys(importedTags);
  const totalKeys = configTagKeys.length;

  if (importedTagKeys.length === 0) {
    return true;
  }

  return (
    importedTagKeys.length > 0 &&
    importedTagKeys.length <= totalKeys &&
    importedTagKeys.every((it) => configTagKeys.indexOf(it) !== -1)
  );
};

const hasInvalidTagType = (importedTags: any, configTags: any) => {
  for (const tagName in importedTags) {
    const importedTagType = importedTags[tagName]?.type || "Empty";
    const storedTagType = configTags[tagName]?.type;
    if (storedTagType !== importedTagType) {
      return `for '${tagName}'. Expected '${storedTagType}' but found '${importedTagType}'`;
    }
  }
  return "";
};

const hasInvalidTagValue = (importedTags: any, configTags: any) => {
  if (importedTags.length === 0) {
    return "";
  }
  const formattedTagData = Object.keys(importedTags).map((tagName) => {
    const { type, data_type, options = [] } = configTags[tagName];
    return {
      tag_name: tagName,
      tag_type: type,
      tag_data_type: data_type,
      tag_value: importedTags[tagName]?.value,
      options,
    };
  });
  const validationResult = validateTags(formattedTagData);
  return validationResult;
};

const getMissingTagsDetails = (importedTags: any, configTags: any) => {
  try {
    const configTagKeys = Object.keys(configTags);
    const importedTagKeys = Object.keys(importedTags);

    const invalidTagKeys = importedTagKeys.filter(
      (itg) => configTagKeys.indexOf(itg) === -1
    );

    const [invalidTagName, ...otherInvalidTagNames] = invalidTagKeys;
    const numberOfOtherInvalidTags = otherInvalidTagNames.length;

    return `Tag '${invalidTagName}'${
      numberOfOtherInvalidTags > 0
        ? ` (and ${numberOfOtherInvalidTags} more)`
        : ""
    } not found in selected configuration.`;
  } catch {
    return "";
  }
};

const validateImportedTags = (csvData: any, selectedConfig: any) => {
  for (let i = 0; i < csvData.length; i++) {
    const csvRowIndex = i + 2;
    const record = csvData[i];
    const importedTags = record.tags || {};
    const configTags = selectedConfig.tags;
    if (!hasSubsetOfKeys(importedTags, configTags)) {
      return `Tag details mismatch at row ${csvRowIndex}. ${getMissingTagsDetails(
        importedTags,
        configTags
      )}`;
    }

    const invalidTagTypeError = hasInvalidTagType(
      importedTags,
      selectedConfig.tags
    );
    if (invalidTagTypeError) {
      return `Tag details mismatch at row ${csvRowIndex} ${invalidTagTypeError}`;
    }

    const invalidTagValueError = hasInvalidTagValue(
      importedTags,
      selectedConfig.tags
    );
    if (invalidTagValueError) {
      return `Tag value mismatch at row ${csvRowIndex}. ${invalidTagValueError}`;
    }
  }

  return "";
};

export default validateImportedTags;
