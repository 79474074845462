import React from "react";

import {
  Header,
  MultifilteredDataTable,
  ServiceUnavailable,
} from "../UiComponents";

import { columns } from "./columnConfigs";
import ApplicationSelector from "./ApplicationSelector";
import mockApi from "./mockApi";
import {
  ApplicationContextProvider,
  useApplicationContext,
} from "../../contexts/ApplicationContext";

const GridController: React.FC<any> = ({ data, gridControls }) => (
  <Header
    route="deviceInstallation"
    headerText={
      <>
        Total Devices: <strong>{data ? data.summary.total_devices : 0}</strong>
      </>
    }
  >
    {gridControls}
  </Header>
);

const DeviceInstallation = () => {
  const { selectedApplication } = useApplicationContext();
  if (!(selectedApplication.services || {}).device_installation) {
    return (
      <>
        <ApplicationSelector />
        <ServiceUnavailable>
          Installation service is not available for this application
        </ServiceUnavailable>
      </>
    );
  }

  return (
    <>
      <ApplicationSelector />
      <MultifilteredDataTable
        endPoint="device-installation"
        gridController={GridController}
        columns={columns}
        selectedApplication={selectedApplication}
        dataLookupKey="devices"
        totalRecordsLookupKey="total_devices"
      />
    </>
  );
};

//TODO: ApplicationContext should be elevated to Application Root. Currently this is duplicated in all pages.
const Wrapper = () => {
  return (
    <ApplicationContextProvider>
      <DeviceInstallation />
    </ApplicationContextProvider>
  );
};
export default Wrapper;
