import React from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";

import { TextField, InputLabel } from "@material-ui/core";
import { ICustomTextFieldProps } from "./types";

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: "1px solid #464659",
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      margin: "15px 20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    value: {
      color: "#FFFFFF",
      backgroundColor: "#2B2B36",
      marginTop: "1em",
      height: "3em",
      display: "flex",
      alignItems: "center",
      paddingLeft: "1em",
      border: "1px solid #474545",
    },
  })
);

const ReadOnlyField: React.FC<any> = ({ label, value, style = {} }) => {
  const classes = useStyles();
  return (
    <div className={classes.fieldWrapper} style={style}>
      <InputLabel
        classes={{ root: classes.labels }}
        htmlFor="selectApplication"
      >
        {label}
      </InputLabel>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

const CustomTextField: React.FC<ICustomTextFieldProps> = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  readOnly,
  style = {},
}) => {
  const classes = useStyles();
  if (readOnly) {
    return <ReadOnlyField label={label} value={value} style={style} />;
  }
  return (
    <div className={classes.fieldWrapper} style={style}>
      <CssTextField
        fullWidth
        name={name}
        label={label}
        id={name}
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.labels,
        }}
        onChange={(event: any) => onChange && onChange(event.target.value)}
        value={value}
        style={{ paddingTop: "0", marginTop: "0" }}
        onBlur={(event: any) => onBlur && onBlur(event.target.value)}
      />
    </div>
  );
};

export default CustomTextField;
