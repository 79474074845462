import React, { FC, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateFnsUtils from "@date-io/date-fns";
import { API } from "../../api/property";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Backdrop, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from "notistack";
import ErrorDialog from "../../utils/ErrorDialog";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import StepLabel from "@material-ui/core/StepLabel";
const textFieldBorder = "1px solid #464659";
const buttonBorder = "1px solid rgba(235,235,245,0.35)";
const stepsColor = "red !important";

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const DateTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
    },
    "& .MuiInput-root": {
      border: `${textFieldBorder}`,
      marginTop: "32px",
      backgroundColor: "#1D1D25",
      borderRadius: "2px",
      boxSizing: "border-box",
      padding: "2px",
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiIconButton-root": {
      color: "#ffffff",
    },
  },
})(KeyboardDatePicker);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      color: "#FFFFFF",
      fontSize: "21px",
      lineHeight: "25px",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "19px",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    textfield1: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
      marginLeft: "-26px",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${textFieldBorder}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: `${buttonBorder}`,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    marginLeft15: {
      marginLeft: "15px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    bgColor: {
      background: "#2B2B36",
      margin: "1px 0",
      padding: "7px 0",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcOuterPadding": {
        padding: "10px 20px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        margin: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: `${buttonBorder}`,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    btnDelRole: {
      textDecoration: "underline",
      color: "#0089ff !important",
      marginLeft: "92.5em",
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: `${buttonBorder}`,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    isExpand: {
      position: "relative",
      top: "1rem",
    },

    stepperRoot: {
      width: "50%",
      margin: "0 auto",
    },
    stepactive: {
      color: "#FFF",
    },
    stepname: {
      color: "#FFF",
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    alternativeLabel: {
      color: "yellow !important",
      "&$active": {
        color: `${stepsColor}`,
      },
    },
    labelContainer: {
      color: `${stepsColor}`,
      "&$alternativeLabel": {
        marginTop: 0,
        color: "orange !important",
      },
      "&$active": {
        color: `${stepsColor}`,
      },
      "&$completed": {
        color: "green !important",
      },
    },
    completed: {
      color: "#0089ff !important",
    },
    step: {
      "&$completed": {
        color: "#0089ff",
      },
      "&$active": {
        color: "cornflowerblue",
      },
      "&$disabled": {
        color: "red",
      },
    },
    active: {
      color: "cornflowerblue !important",
    },
    steplabel: {
      color: "#FFF",
      "& span": {
        color: "darkgray !important",
      },
      "& .active": {
        color: "green !important",
      },
    },
    hidden: {
      display: "none",
    },
    spaceLeftSite: {
      display: "flex",
      alignItems: "flex-end",
      paddingLeft: "10px",
    },
    rightSec1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "13px",
        padding: "10px 25px",
        //backgroundColor: "#2B2B36",
        color: "white",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    appTitle: {
      width: "100%",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
      width: "50em",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    levelSearch: {
      height: "50px",
      backgroundColor: "#2B2B36",
      border: "transparent",
      color: "white",
      marginTop: "2em",
      paddingLeft: "23px"
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },

  });

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#1D1D25",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "3px 0",
      background: "#1D1D25",
    },
  })
)(TableCell);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 20px",
    padding: 0,
    color: "#FFFFFF",
    overflow: "hidden",
  },
}))(MuiDialogContent);

interface ComponentProps {
  onEditUser: any;
  editItem: any;
  userRoles: any;
}

const EditUserWrapper: FC<ComponentProps> = (props: ComponentProps) => {
  const classes = useStyles();
  const { user1 } = useStore();

  const handleCancel = () => {
    props.onEditUser();
    setApplication_level_id([]);
    setApplication_level_name([]);
  };
  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };

  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmailAddr] = React.useState("");
  const [reload, setReload] = React.useState(false);
  const [application, setApplicationName] = React.useState("");
  const [editUserRole, setEditUserRole] = React.useState("");
  const [editUser, setEditUser] = React.useState("");
  const [editUserLevel, setEditUserLevel] = React.useState("");
  const [appid, setAppId] = React.useState(0);
  const [user, setUser] = React.useState(0);
  const [levels, setLevels] = React.useState([] as any);


  const [permDialog, setPermDialogOpen] = React.useState(false);
  const openPermDialog = () => {
    setPermDialogOpen(true);
  };
  const dialogPermClose = () => {
    setPermDialogOpen(false);
  };

  const [validToDate, setValidToDate] = React.useState<Date | null>(new Date(props.editItem.valid_to));
  const [validFromDate, setValidFromDate] = React.useState<Date | null>(
    new Date(props.editItem.valid_from)
  );
  const validToDateChange = (date: Date | null) => {
    setValidToDate(date);
  };
  const validFromDateChange = (date: Date | null) => {
    setValidFromDate(date);
  };

  const [search, setSearch] = React.useState("");
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };

  const [application_level_id, setApplication_level_id] = React.useState([] as any);
  const [application_level_name, setApplication_level_name] = React.useState([] as any);
  const highlightHandler = (event: React.ChangeEvent<HTMLInputElement>, level_id: any, level_name: any) => {

    const checked = event.target.checked;
    let temp = [...application_level_id]
    let temp1 = [...application_level_name]
    if (checked) {
      temp.push(level_id)
      temp1.push(level_name)
    }
    else {
      temp.splice(temp.indexOf(level_id), 1);
      temp1.splice(temp1.indexOf(level_name), 1);
    }
    setApplication_level_id(temp);
    setApplication_level_name(temp1);
  };

  const d = new Date(`${validToDate}`);
  const dateValidTo =
    d.getFullYear() +
    "-" +
    `${("0" + (d.getMonth() + 1)).slice(-2)}` +
    "-" +
    d.getDate() +
    " " +
    "23:59:59"
  const d1 = new Date(`${validFromDate}`);
  const dateValidFrom =
    d1.getFullYear() +
    "-" +
    `${("0" + (d1.getMonth() + 1)).slice(-2)}` +
    "-" +
    d1.getDate() +
    " " +
    "00:00:01"

  const saveEditUserAction = () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const USERS_API = `${API["GETALLAPPLS"]}/${appid}/users/${user}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
      body: JSON.stringify({
        pm_s_id: pmSId,
        pm_s_token: pmST,
        user: {
          email_id: email,
          application_level_id: application_level_id,
          role_id: userRoles_data,
          valid_from: dateValidFrom,
          valid_to: dateValidTo,
        },
      }),
    };
    fetch(USERS_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              saveEditUserAction();
            }).catch(function (error) {
              user1.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user1.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("User changed.", { variant: "success" });
          setTimeout(function () {
            props.onEditUser();
            setApplication_level_id([]);
            setApplication_level_name([]);
          }, 500);
        }
        else {
          setError(true);
          setErrMsg("Error occured. Please try again after sometime.");
        }
      })
    })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [error, setError] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const updateError = () => {
    setError(false);
    setErrMsg("");
  };

  function getSteps() {
    return ["Basic Information", "Roles & Permission"];
  }
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    const newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const [rolesDataFind, setRolesDataFind] = React.useState<any>({});
  useEffect(()=>{
    const rolesData =
    props.userRoles.find((e: any) => e.role_name.toUpperCase() === editUserRole.toUpperCase() ) || {};
    setRolesDataFind(rolesData);
  },[editUserRole, props])

 


  const [userLevel, setUserLevel] = React.useState("");
  const onChangeLevel = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserLevel(event.target.value);
  };
  const [usersInput, setUsersInput] = React.useState([] as any);

  let levelsData = usersInput.filter((e: any) => {
    return e.application_id === appid;
  })
  let level_data = levelsData[0] && levelsData[0].levels
  let role_data = levelsData[0] && levelsData[0].roles
  
  const [userRoles_data, setUserRoles_data] = React.useState("");
  React.useEffect(() => {
    setUserRoles_data(editUserLevel)
  }, [setUserRoles_data, editUserLevel]);


  const onChangeRoles = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserRoles_data(event.target.value);
    const data =   role_data.find((e:any)=> e.role_id === event.target.value )
    setRolesDataFind(data);
  };

  React.useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    setAppId(props.editItem.application_id);
    setEmailAddr(props.editItem.email_id);
    setUser(props.editItem.user_id);
    setApplicationName(props.editItem.application_name);
    setEditUserRole(props.editItem.role_name);
    setEditUser(props.editItem.role_id);
    setValidToDate(props.editItem.valid_to);
    setValidFromDate(props.editItem.valid_from);
    setUserRoles_data(props.editItem.role_id)
    if (props.editItem.application_level) {
      props.editItem.application_level.forEach((element: any) => {
        application_level_name.push(element.level_name);
        application_level_id.push(element.level_id)

      });
    }

    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    const USERS_INPUT = API["GETUSERSAPPLS"] + `?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    fetch(USERS_INPUT, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    })
      .then((resp) => resp.json())
      .then(function (response) {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              setReload(!reload)
            }).catch(function (error) {
              user1.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user1.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        setUsersInput(response.users_input[0].applications)
        const selectedApp = response.users_input[0].applications.find((a: any) => a.application_id === props.editItem.application_id)
        setLevels(selectedApp.application_level)
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [props.editItem, reload]);

  return (
    <div className={classes.root}>
      <div className={classes.stepperRoot}>
        <Stepper activeStep={activeStep} className={classes.stepactive}>
          {steps.map((label, index) => {
            return (
              <Step key={label} className={classes.stepname}>
                <StepLabel
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.step,
                      completed: classes.completed,
                      active: classes.active,
                    },
                  }}
                  className={classes.steplabel}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <List
        dense={true}
        classes={{ root: classes.noSpace }}
        className={activeStep !== 0 ? classes.hidden : classes.spaceTop}
      >
        <ListItem classes={{ root: classes.noSpace }}>
          <ListItemText
            classes={{ root: classes.noSpace }}
            primary=""
            primaryTypographyProps={{
              style: { color: "#FFFFFF", fontWeight: "bold", fontSize: "16px" },
            }}
            secondaryTypographyProps={{
              style: { color: "#FCFCFC", fontSize: "12px", marginTop: "10px" },
            }}
          />
        </ListItem>
      </List>
      <form className={classes.form} noValidate method="post">
        <Grid container className={activeStep !== 0 ? classes.hidden : ""}>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="email"
              label="Email Address"
              type="text"
              id="email"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setEmailAddr(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={email}
            />
          </Grid>
          <Grid item xs={6}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="selectApplication"
              >
                Application
              </InputLabel>
              <TextField
                className={classes.textfield}
                // style={{marginLeft:"-22px"}}
                fullWidth
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={application}
                id="selectApplication"
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>

        <List
          dense={true}
          classes={{ root: classes.noSpace }}
          className={activeStep !== 1 ? classes.hidden : classes.space2Top}
        >
          <ListItem classes={{ root: classes.noSpace }}>
            <ListItemText
              classes={{ root: classes.noSpace }}
              primary=""
              primaryTypographyProps={{
                style: {
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  fontSize: "16px",
                },
              }}
              secondaryTypographyProps={{
                style: {
                  color: "#FCFCFC",
                  fontSize: "12px",
                  marginTop: "10px",
                },
              }}
            />
          </ListItem>
        </List>

        <div className={activeStep !== 1 ? classes.hidden : classes.space2Top}>
          <AccordionDetails classes={{ root: classes.accordionRoot }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  className={`${classes.spaceLeft} ${classes.spaceRight}`}
                >
                  <div className={classes.fieldWrapper}>
                    <InputLabel
                      classes={{ root: classes.labels }}
                      htmlFor="selectRole"
                    >
                      Role *
                    </InputLabel>
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      select
                      variant="outlined"
                      classes={{ root: classes.fieldRoot }}
                      inputProps={{ className: classes.field }}
                      // value={editUserRole}
                      value={userRoles_data}
                      id="selectRole"
                      SelectProps={{
                        classes: {
                          iconOutlined: classes.icon,
                        },
                      }}
                      onChange={onChangeRoles}
                    >
                      {Array.isArray(role_data) && role_data.map((option: any) => (
                        <option className={classes.options} value={option.role_id}>
                          {option.role_name}
                        </option>
                      ))}
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={3} className={classes.spaceLeft}>
                  <div className={classes.fieldWrapper}>
                    <Button
                      variant="outlined"
                      className={classes.vp}
                      onClick={openPermDialog}
                    >
                      View Permissions
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={6} className={classes.spaceRight}>
                  <DateTextField
                    fullWidth
                    variant="inline"
                    placeholder="YYYY-MM-DD"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="valid-from"
                    label="Valid From"
                    value={validFromDate}
                    inputProps={{ className: classes.field }}
                    className={classes.fieldWrapper}
                    InputLabelProps={{
                      className: classes.labels,
                    }}
                    PopoverProps={{
                      classes: {
                        paper: classes.datePickerRoot,
                      },
                    }}
                    onChange={validFromDateChange}
                    keyboardIcon={<DateRangeIcon />}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={6} className={classes.spaceLeft}>
                  <DateTextField
                    fullWidth
                    variant="inline"
                    placeholder="YYYY-MM-DD"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="valid-to"
                    label="Valid To"
                    value={validToDate}
                    inputProps={{ className: classes.field }}
                    className={classes.fieldWrapper}
                    InputLabelProps={{
                      className: classes.labels,
                    }}
                    PopoverProps={{
                      classes: {
                        paper: classes.datePickerRoot,
                      },
                    }}
                    onChange={validToDateChange}
                    keyboardIcon={<DateRangeIcon />}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid
                  container
                  className={classes.appContainer}
                >
                  <div className={classes.appTitle}>
                    <Typography className={classes.colorWhite}>
                      Application Levels *
                    </Typography>
                  </div>
                  <Grid item xs={12} sm={12} container>
                    <TableContainer
                      component={Paper}
                      className={classes.tableWrapper}
                    >
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                        </TableHead>
                        <TableHead style={{ color: "#FFFFFF" }} >
                          <input type="search" className={classes.levelSearch} placeholder="Search..." onChange={(event) => handleChangeSearch(event)} />
                        </TableHead>
                        <TableBody>
                          {levels.filter((item: any) => item.level_name.toLowerCase().includes(search.toLowerCase())).map((row: any, index: number) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <Checkbox
                                  className={classes.bgColorBlue}
                                  aria-label="level"
                                  value={row.level_name}
                                  onChange={(event) => { highlightHandler(event, row.level_id, row.level_name) }}
                                  checked={application_level_id.includes(row.level_id)}
                                />

                                {row.level_name}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </AccordionDetails>
        </div>

        <Grid container className={activeStep !== 1 ? classes.hidden : ""}>
          <Dialog
            classes={{ paper: classes.dialogRoot }}
            onClose={dialogPermClose}
            aria-labelledby="customized-dialog-title"
            open={permDialog}
          >
            <DialogTitle id="customized-dialog-title" onClose={dialogPermClose}>
              Permissions
            </DialogTitle>
            <Grid container spacing={0} className="dcOuterPadding">
              <Grid item xs={6}>
                <Typography className="dcTitle dcHeader"></Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="dcFieldValue">Read</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="dcFieldValue">Write</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="dcFieldValue">Delete</Typography>
              </Grid>
            </Grid>
            <DialogContent>
              {Array.isArray(rolesDataFind?.permission_mapping) &&
                rolesDataFind?.permission_mapping.map((app: any, index: any) => {
                  const permissions =
                    (app.permission && app.permission.split("|")) || [];
                  return (
                    <>
                      <Grid container className={classes.marginLeft15}>
                        <Grid container item xs={12}>
                          <Grid item xs={6}>
                            <Typography
                              className="dcFieldValue"
                            >
                              {app.component_desc}
                            </Typography>
                          </Grid>
                          {["read", "write", "delete"].map((i: any) => (
                            <Grid item xs={2}>
                              <Checkbox
                                defaultChecked={permissions.includes(i)}
                                disabled
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                className={classes.bgColorBlue}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <AccordionDetails
                        classes={{ root: classes.accordionRoot }}
                      ></AccordionDetails>
                    </>
                  );
                })}
            </DialogContent>
          </Dialog>
        </Grid>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={activeStep === 0 ? handleCancel : handleBack}
          >
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={
              activeStep === steps.length - 1 ? saveEditUserAction : handleNext
            }
          >
            {activeStep === steps.length - 1 ? "Save User" : "Next"}
          </Button>
        </div>
        <Backdrop
          className={classes.backdrop}
          open={backDrop}
          onClick={backDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </div>
  );
};

interface ChildComponentProps {
  onEditUser: any;
  editItem: any;
  userRoles: any;
}

export default function EditUser({
  editItem,
  onEditUser,
  userRoles,
}: ChildComponentProps) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <EditUserWrapper
        editItem={editItem}
        userRoles={userRoles}
        onEditUser={onEditUser}
      />
    </SnackbarProvider>
  );
}
