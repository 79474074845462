import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import AlertRed from "../../../../assets/AlertRed.png";
import AlertYellow from "../../../../assets/AlertYellow.png";

//TODO: This component is taken as is from old ShowDevices file
export const EXPIRE_LIMITED_DAYES_MAX = 90;
export const EXPIRE_LIMITED_DAYES = 30;
export const YELLOW_DAYS = 30;
export const RED_DAYS = 5;
export const YELLOW_DAYS_MESSAGE = `This device certificate is expiring within ${YELLOW_DAYS} days, please renew the certificate.`;
export const RED_DAYS_MESSAGE = `This device certificate is expiring within ${RED_DAYS} days, please renew the certificate.`;

const AlertTooltipYellow = withStyles({
  tooltip: {
    color: "#FDB840",
    background: "#2B2B36",
  },
})(Tooltip);

const AlertTooltipRed = withStyles({
  tooltip: {
    color: "#D72B3F",
    background: "#2B2B36",
  },
})(Tooltip);

const CertificateStatusIndicator: React.FC<any> = ({ record }) => {
  if (record.expiry_in_days == null) {
    return null;
  } else if (record.expiry_in_days < 0) {
    return (
      <AlertTooltipRed
        title={`This device certificate expired ${Math.abs(
          record.expiry_in_days
        )} days ago.`}
      >
        <img src={AlertRed} />
      </AlertTooltipRed>
    );
  } else if (
    record.expiry_in_days <= YELLOW_DAYS &&
    record.expiry_in_days > RED_DAYS
  ) {
    return (
      <AlertTooltipYellow title={YELLOW_DAYS_MESSAGE}>
        <img src={AlertYellow} />
      </AlertTooltipYellow>
    );
  } else if (record.expiry_in_days <= RED_DAYS) {
    return (
      <AlertTooltipRed title={RED_DAYS_MESSAGE}>
        <img src={AlertRed} />
      </AlertTooltipRed>
    );
  } else {
    return null;
  }
};

export default CertificateStatusIndicator;
