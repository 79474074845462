import React, { useState } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

import { Backdrop, LinearProgress } from "@material-ui/core";

import { PAGES } from "../../../../utils/constant";
import useApi from "../../../../hooks/useApi";
import { CustomButton, LoadingIndicator } from "../../../UiComponents";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import FullScreenView from "../../../UiComponents/AddRemoveImage/ImageViewer/FullScreenView";
import ThumbnailView from "../../../UiComponents/AddRemoveImage/ImageViewer/ThumbnailView";
import DeleteDevice from "./DeleteDevice";
import { NavLink } from "react-router-dom";
import DeviceHistory from "./DeviceHistory";

const shadow_style = "0px 30px 100px #000000";
const border_style = "1px solid rgba(235,235,245,0.35)";

/*TODO: This code is not optimized and  taken as is from  'ShowDevices' page. Only api call was optimized.
There are lots of unused/repeated styles which can be refactored.  
*/

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#2B2B36",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    headerStyle: {
      fontStyle: "normal",
      fontFamily: "Avenir Next",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      lineHeight: "27px",
      color: "#FFFFFF",
    },
    preview: {
      width: "210px",
      border: "1px dashed rgba(255, 255, 255, 0.2)",
      height: "118px",
      display: "flex",
      background: "rgba(0, 0, 0, 0.11)",
      alignItems: "center",
      justifyContent: "center",
    },

    hidden: {
      display: "none",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    tableWrapper2: {
      marginTop: "15px",
      boxShadow: "none",
    },
    formControl: {
      margin: "0 25px",
    },
    tableWrapper1: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
    },
    table1: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      color: "#0089FF !important",
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    cardTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "21px",
      color: "#FFFFFF",
      marginBottom: "16px",
    },
    cardText: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginBottom: "10px",
    },
    CardStyle: {
      background: "#2B2B36",
      borderRadius: "3px",
    },
    cardGrid: {
      marginBottom: "20px",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "850px",
      minWidth: "850px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
        overflowWrap: "anywhere",
        paddingRight: ".5em",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRoot1: {
      backgroundColor: "#2B2B36",
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "1200px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
        overflowWrap: "anywhere",
        paddingRight: ".5em",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        overflowWrap: "anywhere",
        paddingRight: ".5em",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    spaceRight: {
      marginRight: "1em",
      paddingRight: "2em",
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: "16ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    tblpaginate: {
      color: "#fff",
    },
    tblfooter: {
      position: "absolute",
    },
    tblfooter1: {
      position: "relative",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
      justify: "space-between",
    },
    tblpaginatenumber: {
      color: "#fff",
    },
    paginateUser: {
      color: "#fff",
    },
    paginateView: {
      color: "#fff",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "20px",
    },
    expireBlock: {
      display: "flex",
      zIndex: 99,
      flexDirection: "row",
      position: "fixed",
      right: "20px",
      padding: "0px 10px",
      background: "#464659",
      borderRadius: "2px",
      color: "#fff",
      borderLeft: "4px solid #FDB840",
      boxShadow: " 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 5px rgb(0 0 0 / 10%)",
      alignItems: "center",
    },
    expireBtn: {
      width: "63px",
      height: "32px",
      margin: "0px 20px",
      background: "#0089FF",
      border: "1px solid rgba(0, 137, 255, 0.15)",
      borderRadius: "2px",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const isOptedForInstallationService = (uApplication: any, app: any) => {
  return true;
};

const createListFields = (fields: any) => {
  const array = [];
  for (const key in fields) {
    array.push({
      name: key,
      value: fields[key],
    });
  }
  return array;
};

const getConfigData = (data: any) => {
  const array = [];
  for (const key in data) {
    array.push({
      key: key,
      value: data[key],
    });
  }
  return array;
};

const extractTags = (deviceDetails: any) => {
  const temp: any[] = [];
  for (const tag in deviceDetails.device_config.tags) {
    if (deviceDetails.device_config.tags[tag].type === "Tabular") {
      temp.push({
        tag_name: tag,
        tag_value: deviceDetails.device_config.tags[tag].value,
        tag_type: deviceDetails.device_config.tags[tag].type,
        table_values: deviceDetails.device_config.tags[tag].table_values,
      });
    } else if (
      deviceDetails.device_config.tags[tag].type === "Text" ||
      deviceDetails.device_config.tags[tag].type === "Dropdown"
    ) {
      temp.push({
        tag_name: tag,
        tag_value: deviceDetails.device_config.tags[tag].value,
        tag_type: deviceDetails.device_config.tags[tag].type,
      });
    }
  }
  return temp;
};

const ViewDetailsDialog: React.FC<any> = ({ record, onDone }) => {
  const classes = useStyles();
  const { selectedApplication } = useApplicationContext();
  const [selectedImageForFullScreenView, setSelectedImageForFullScreenView] =
    useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDeviceHistoryDialog, setShowDeviceHistoryDialog] = useState(false);

  const { data: deviceDetails, status } = useApi(
    `/applications/${selectedApplication.application_id}/devices/${record.device_dlm_id}`,
    {
      method: "GET",
      responseDataFormatter: (data: any = [{}]) => {
        const deviceDetailsRaw = data.device;
        const formattedTags = extractTags(deviceDetailsRaw);
        return { ...deviceDetailsRaw, formattedTags };
      },
    }
  );

  if (status.pending || !deviceDetails) {
    return <LoadingIndicator />;
  }

  if (selectedImageForFullScreenView) {
    return (
      <FullScreenView
        image={selectedImageForFullScreenView}
        requestPage={PAGES.DEVICES}
        imageAccessBaseUrl={`/applications/${selectedApplication.application_id}/device-installation/${record.device_dlm_id}/device-img`}
        onClose={() => setSelectedImageForFullScreenView(null)}
      />
    );
  }

  if (showDeleteDialog) {
    return <DeleteDevice record={record} onDone={onDone} />;
  }

  if (showDeviceHistoryDialog) {
    return (
      <DeviceHistory
        record={record}
        onDone={() => setShowDeviceHistoryDialog(false)}
      />
    );
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      onClose={onDone}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <DialogTitle id="customized-dialog-title" onClose={onDone}>
        View Device Details
      </DialogTitle>
      <Backdrop className={classes.backdrop} open={status.pending}>
        <Grid container direction="column" alignItems="center">
          <LinearProgress style={{ color: "#FCFCFC", width: "30%" }} />
          <Typography
            style={{
              fontFamily: "Avenir Next",
              fontSize: "14px",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              margin: "1em",
              color: "#FFFFFF",
            }}
          >
            Please wait. Request is in progress.
          </Typography>
        </Grid>
      </Backdrop>
      <DialogContent>
        <Grid container spacing={0} className="dcOuter">
          <Grid item xs={4}>
            <Typography className="dcTitle">Application Name</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.application_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device Configuration</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.config_name
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">
              Connection Configuration
            </Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.conn_config
                ? deviceDetails.conn_config.config_name
                : "Not applicable"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device Type</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.device_type
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Authentication Type</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.auth_type
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Edge Type</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.edge_type
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device Name</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_name ? deviceDetails.device_name : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device ID</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_id ? deviceDetails.device_id : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Serial Number</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.serial_num ? deviceDetails.serial_num : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Cloud Gateway</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.cloud_gateway ? deviceDetails.cloud_gateway : ""}
            </Typography>
          </Grid>
          {deviceDetails.device_config.auth_type === "X509" && (
            <Grid item xs={4}>
              <Typography className="dcTitle">Certificate Expiry</Typography>
              <Typography className="dcFieldValue">
                {deviceDetails.cert_expiry ? deviceDetails.cert_expiry : ""}
              </Typography>
            </Grid>
          )}
          <Grid item xs={4}>
            <Typography className="dcTitle">Application Level</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.application_level_name
                ? deviceDetails.application_level_name
                : ""}
            </Typography>
          </Grid>
          {isOptedForInstallationService({}, {}) &&
            deviceDetails.device_install_status_name && (
              <Grid item xs={4}>
                <Typography className="dcTitle">Installation Status</Typography>
                <Typography className="dcFieldValue">
                  {deviceDetails.device_install_status_name}
                </Typography>
              </Grid>
            )}
          {createListFields(deviceDetails.fields).map((field: any) => {
            return (
              <Grid item xs={4}>
                <Typography className="dcTitle">{field.name}</Typography>
                <Typography className="dcFieldValue">{field.value}</Typography>
              </Grid>
            );
          })}
          {createListFields(deviceDetails.runtime_metadata).map(
            (field: any) => {
              return (
                <Grid item xs={4}>
                  <Typography className="dcTitle">{field.name}</Typography>
                  <Typography className="dcFieldValue">
                    {field.value === -1 || field.value === "-1" ? (
                      <Typography style={{ color: "#D72B3F" }}>
                        INVALID DATA
                      </Typography>
                    ) : (
                      field.value
                    )}
                  </Typography>
                </Grid>
              );
            }
          )}
        </Grid>
        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontSize: "19px", fontWeight: "bold" }}>
              Device Images
            </Typography>
            <CustomButton
              variant="outlined-white"
              onClick={() => setShowDeviceHistoryDialog(true)}
            >
              Device History
            </CustomButton>
          </Grid>

          {deviceDetails.device_img ? (
            deviceDetails.device_img.map((item: any) => {
              return (
                <div style={{ margin: "1em" }}>
                  <Grid container direction="column" alignItems="center">
                    <div style={{ color: "#FCFCFC", margin: "0.5em" }}>
                      <b>{item.name ? item.name : ""}</b>
                    </div>
                    <div className={classes.preview}>
                      <ThumbnailView
                        image={item}
                        imageAccessBaseUrl={`/applications/${selectedApplication.application_id}/device-installation/${record.device_dlm_id}/device-img`}
                        requestPage={PAGES.DEVICES}
                        onClick={() => setSelectedImageForFullScreenView(item)}
                      />
                    </div>
                  </Grid>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Tags"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "19px",
                    },
                  }}
                  secondary=""
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          {deviceDetails.formattedTags.map((tag: any, index: any) => {
            if (tag.tag_type === "Text" || tag.tag_type === "Dropdown") {
              return (
                <Grid item xs={4}>
                  <Typography className="dcTitle">{tag.tag_name}</Typography>
                  <Typography className="dcFieldValue">
                    {tag.tag_value}
                  </Typography>
                </Grid>
              );
            }
          })}
          {deviceDetails.formattedTags.map((tag: any, index: any) => {
            if (tag.tag_type === "Tabular") {
              return (
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "20px", marginBottom: "5px" }}
                >
                  <Typography className="dcTitle">{tag.tag_name}</Typography>

                  <TableContainer
                    component={Paper}
                    className={classes.tableWrapper2}
                  >
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          {tag.tag_value
                            .split("|")
                            .map((value: any, index: any) => {
                              return (
                                <StyledTableCell>
                                  <Grid container direction="row">
                                    <Grid item>
                                      <Typography
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {value}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </StyledTableCell>
                              );
                            })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {tag.table_values.map((value: any, index: any) => {
                          return (
                            <StyledTableRow>
                              {value.map((item: any, index: any) => {
                                return (
                                  <StyledTableCell>{item}</StyledTableCell>
                                );
                              })}
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              );
            }
          })}
        </Grid>
        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Properties"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "19px",
                    },
                  }}
                  secondary=""
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          {deviceDetails.device_config ? (
            getConfigData(deviceDetails.device_config.properties).map(
              (item) => (
                <Grid item xs={4}>
                  <Typography className="dcTitle">{item.key}</Typography>
                  <Typography className="dcFieldValue">{item.value}</Typography>
                </Grid>
              )
            )
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Device Twin"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "19px",
                    },
                  }}
                  secondary=""
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          {deviceDetails.device_twin ? (
            <Typography className="dcFieldValue">
              <pre>{JSON.stringify(deviceDetails.device_twin, null, 2)}</pre>
            </Typography>
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Device Connection String"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "19px",
                    },
                  }}
                  secondary=""
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Typography className="dcFieldValue">
            {deviceDetails.device_conn_string
              ? deviceDetails.device_conn_string
              : "Not found"}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDeleteDialog(true);
          }}
          variant="outlined"
          className="dcBtDelete"
        >
          Delete
        </Button>
        <NavLink
          to={`/edit-device/${record.device_dlm_id}`}
          style={{ textDecoration: "none" }}
        >
          <Button onClick={() => true} variant="contained" className="dcBtEdit">
            Edit
          </Button>
        </NavLink>
      </DialogActions>
    </Dialog>
  );
};

export default ViewDetailsDialog;
