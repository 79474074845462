import React, { FC, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { WithStyles } from "@material-ui/core";
import { SnackbarProvider, useSnackbar } from "notistack";
import { API } from "../../api/property";
import {
  CircularProgress,
  Backdrop,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import callXhrRequest from "../../utils/xhrRequestHandler";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';
const borderCss = "1px solid #464659";
const borderBox = "border-box";
const borderCss2 = "1px solid rgba(235,235,245,0.35)";

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: borderCss,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: borderCss,
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0.5em 24px",
      backgroundColor: "#2B2B36",
    },
    formTitle: {
      color: "#FCFCFC",

      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
    },
    subtitle: {
      color: "#373746",
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: "23px",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
     },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    labels1: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
      marginTop: "1em",
      marginBottom: "1em",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: borderCss,
      borderRadius: "2px",
      boxSizing: borderBox,
    },

    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
      /* marginBottom: '5px'   */
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: borderCss2,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNDevice: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: borderCss,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    buttonText: {
      color: "#0089FF",
      margin: "1em",
      textTransform: "none",
      fontSize: "14px",
    },
    addNewFieldText: {
      color: "#0089FF",
      marginTop: "1em",
      marginBottom: "1em",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "19px",
    },
    helperfield: {
      color: "#FCFCFC",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px",
    },
    textfield1: {
      color: "#FCFCFC",
      border: `1px solid #464659`,
      boxSizing: borderBox,
      borderRadius: "2px",
      backgroundColor: "#2B2B36",
    },
    dropButton: {
      textTransform: "none",
      fontSize: "16px",
      margin: "0px",
      border: "1px solid #6E6E79",
      boxSizing: borderBox,
      borderRadius: "4px",
    },
    topGrid: {
      marginTop: "1em",
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingBottom: "30px",
      background: "#2B2B36",
      borderRadius: "4px",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: borderCss2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: borderCss2,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },

    items: {
      color: "#FCFCFC",
      marginLeft: "2em",
      marginRight: "2em",
      marginTop: "1em",
      marginBottom: "1em",
    },
    dividerColor: {
      background: "#464659",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
  });

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface ComponentProps {
  onEditConfig: any;
  editItem: any;
}

const EditConfigWrapper: FC<ComponentProps> = (props: ComponentProps) => {
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { user } = useStore();

  const classes = useStyles();
  const [app, setApp] = React.useState("");
  const [appName, setAppName] = React.useState("");

  const [value, setValue] = React.useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };

  const [api_url, setApiurl] = React.useState("");
  const handleChangeUrl = (event: any) => {
    setApiurl(event.target.value);
  };

  const [showpass, setShowpass] = React.useState("password");

  const [bearer, setBearer] = React.useState("");
  const handleChangeBearer = (event: any) => {
    setBearer(event.target.value);
  };

  const [appid, setAppid] = React.useState("");
  const handleChangeAppid = (event: any) => {
    setAppid(event.target.value);
  };

  const [config, setConfig] = React.useState("" as any);
  const handleChangeConfig = (event: any) => {
    setConfig(event.target.value);
  };
  const [configDesc, setConfigDesc] = React.useState("" as any);
  const handleChangeConfigDesc = (event: any) => {
    setConfigDesc(event.target.value);
  };

  const { enqueueSnackbar } = useSnackbar();

  const validateField = () => {
    function testJSON(text: any) {
      if (typeof text !== "string") {
        return false;
      }
      try {
        JSON.parse(text);
        return true;
      } catch (error) {
        return false;
      }
    }
    const letterNumber = /^[0-9a-zA-Z_-\s]*$/;
    let flag = true;
    if (app === "") {
      enqueueSnackbar("Please select Application", { variant: "error" });
      flag = false;
    } else if (config === "") {
      enqueueSnackbar("Please enter configuration name", { variant: "error" });
      flag = false;
    } else if (configDesc === "") {
      enqueueSnackbar("Please enter configuration description", {
        variant: "error",
      });
      flag = false;
    }

    //loriot validations
    else if (value === "LORIOT" && bearer === "") {
      enqueueSnackbar("Please enter valid Bearer Token", { variant: "error" });
      flag = false;
    } else if (value === "LORIOT" && appid === "") {
      enqueueSnackbar("Please enter valid Loriot application ID", {
        variant: "error",
      });
      flag = false;
    } else if (value === "LORIOT" && api_url === "") {
      enqueueSnackbar("Please enter valid API URL", { variant: "error" });
      flag = false;
    } else if (!(letterNumber.test(config) && config.length < 50)) {
      enqueueSnackbar(
        "Invalid configuration name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
      flag = false;
    } else if (!letterNumber.test(configDesc)) {
      enqueueSnackbar(
        "Invalid configuration description (only 0-9,A-Z,a-z,_,- allowed).",
        { variant: "error" }
      );
      flag = false;
    }

    if (flag) {
      saveConfigAction();
    }
  };

  const saveConfigAction = () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    let metadata = {} as any;

    if (value === "LORIOT") {
      metadata["api_url"] = api_url;
      metadata["application_id"] = appid;
      metadata["bearer_token"] = bearer;
      metadata["config_type"] = "LORIOT";
    }

    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));

    const CONFIG_API = `${API["GETALLAPPLS"]}/${app}/conn-configs/${props.editItem.conn_config_id}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken.access_token}`,
        "Ocp-Apim-Subscription-Key": `${API_KEY}`,
        "Ocp-Apim-Trace": `true`,
      },
      body: JSON.stringify({
        pm_s_id: pmSessionId,
        pm_s_token: pmSessionToken,
        conn_config: {
          config_name: config,
          config_desc: configDesc,
          metadata: metadata,
        },
      }),
    };
    setbackDrop(true);
    fetch(CONFIG_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
        if (
          (response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))
        ) {
          try {
            const tokenKey = "sso_token";
            //   setbackDrop(true);
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                //   setbackDrop(false);
                saveConfigAction();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
       else if (
          (response && response.status === 200) ||
          (response && response.status === 201)
        ) {
          enqueueSnackbar("Configuration Changed", { variant: "success" });
          setbackDrop(false);
          setTimeout(function () {
            props.onEditConfig();
            setConfig("");
            setConfigDesc("");
            setApiurl("");
            setBearer("");
            setAppid("");
            setApp("");
          }, 500);
        } else {
          enqueueSnackbar("Configuration Change Failed", { variant: "error" });
          setbackDrop(false);
        }
      })
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setConfig(props.editItem.config_name);
    setValue(props.editItem.metadata.config_type);
    setAppid(props.editItem.metadata.application_id);
    setApiurl(props.editItem.metadata.api_url);
    setBearer(props.editItem.metadata.bearer_token);
    setConfigDesc(props.editItem.config_desc);
    setApp(props.editItem.application_id);
    setAppName(props.editItem.application_name);
  }, [props.editItem]);

  const onCancel = () => {
    props.onEditConfig();
    setConfig("");
    setConfigDesc("");
    setApp("");
    setApiurl("");
    setBearer("");
    setAppid("");
  }

  return (
    <div className={classes.root}>
      <div
        className={classes.gridRoot}
        style={{ marginRight: "1.5em", marginBottom: "1.5em", width: "100%" }}
      >
        <Grid
          container
          direction="row"
          xs={12}
          sm={6}
          style={{ marginTop: "1em", marginBottom: "1em" }}
          alignItems="center"
        >
          <BreadcumbComponent route='editConnectionConfig' func={onCancel}/>
        </Grid>
      </div>
      {/* <Typography className={classes.subtitle} >All fields marked (*) are mandatory</Typography> */}

      <FormControl
        component="fieldset"
        style={{ marginTop: "4em", color: "#FFFFFF" }}
      >
        <FormLabel
          component="legend"
          classes={{ root: classes.labels }}
          style={{ color: "#FFFFFF", marginBottom: "0.5em" }}
        >
          Configuration Type
        </FormLabel>
        <RadioGroup
          aria-label="config_type"
          name="controlled-radio-buttons-group"
          value={value}
          style={{ color: "#FFFFFF" }}
        >
          <FormControlLabel
            value="LORIOT"
            control={<Radio style={{ color: "#FFFFFF" }} />}
            label="LORIOT"
          />
        </RadioGroup>
      </FormControl>

      <form className={classes.form} noValidate method="post">
        <Grid container>
          <Grid item xs={6} className={classes.spaceRight}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="selectApplication"
              >
                Select Application *
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                select
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={app}
                id="selectApplication"
                SelectProps={{
                  classes: {
                    /* selectMenu : classes.selectRoot, */
                    iconOutlined: classes.icon,
                  },
                }}
              >
                <option className={classes.options} key={app} value={app}>
                  {appName}
                </option>
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <CssTextField
              fullWidth
              name="config"
              label="Configuration Name *"
              type="Text"
              id="config"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              onChange={(event) => handleChangeConfig(event)}
              value={config}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="api_url"
              label="Loriot API URL *"
              type="Text"
              id="api_url"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              onChange={(event) => handleChangeUrl(event)}
              value={api_url}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <CssTextField
              fullWidth
              name="appid"
              label="Loriot Application ID *"
              type="Text"
              id="appid"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              onChange={(event) => handleChangeAppid(event)}
              value={appid}
            />
          </Grid>
          <Grid container direction="row" xs={12} alignItems="center">
            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="bearer"
                label="Bearer Token *"
                type={showpass}
                id="bearer"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                onChange={(event) => handleChangeBearer(event)}
                value={bearer}
              />
            </Grid>
            <VisibilityIcon
              style={{ color: "#FFFFFF", marginTop: "2em" }}
              onClick={() => {
                if (showpass === "password") {
                  setShowpass("Text");
                } else {
                  setShowpass("password");
                }
              }}
            ></VisibilityIcon>
          </Grid>

          <Grid item xs={12} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="configDesc"
              label="Configuration Description *"
              type="Text"
              id="configDesc"
              /* multiline
                            rows={4} */
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              onChange={(event) => handleChangeConfigDesc(event)}
              value={configDesc}
            />
          </Grid>
        </Grid>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={() => {
              props.onEditConfig();
              setConfig("");
              setConfigDesc("");
              setApp("");
              setApiurl("");
              setBearer("");
              setAppid("");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNDevice}
            onClick={() => validateField()}
          >
            Save Configuration
          </Button>
        </div>
      </form>
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

interface ChildComponentProps {
  onEditConfig: any;
  editItem: any;
}

export default function EditUser({
  editItem,
  onEditConfig,
}: ChildComponentProps) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <EditConfigWrapper editItem={editItem} onEditConfig={onEditConfig} />
    </SnackbarProvider>
  );
}
