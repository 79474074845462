import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import {
  Modal,
  CustomButton,
  LoadingIndicator,
  SubmitButton,
} from "../../../../../../UiComponents";
import useApi from "../../../../../../../hooks/useApi";

import GpsCard from "../GpsCard";
import useGps from "./useGps";
import useStyles from "./CaptureGps.styles";
import { useApplicationContext } from "../../../../../../../contexts/ApplicationContext";

interface ICaptureGPSProps {
  onDone: () => void;
  deviceId: string;
  onSuccess: () => void;
}

const CaptureGPS: React.FC<ICaptureGPSProps> = ({
  onDone,
  deviceId,
  onSuccess,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { gpsCrd, status: gpsQueryStatus, capturePosition } = useGps();
  const { selectedApplication } = useApplicationContext();
  const {
    data: saveGpsResult,
    status: saveGpsStatus,
    trigger: saveGps,
  } = useApi(
    `/applications/${selectedApplication.application_id}/device-location/${deviceId}`,
    {
      method: "PUT",
      deferred: true,
      responseDataFormatter: (responseData: any) => responseData.message,
    }
  );

  useEffect(() => {
    if ((saveGpsResult || "").toLowerCase() === "success") {
      onSuccess();
    }
    if (saveGpsStatus.error) {
      enqueueSnackbar("You are not authorized to perform this operation", {
        variant: "error",
      });
      onDone();
    }
  }, [saveGpsResult, saveGpsStatus.error]);

  useEffect(() => {
    capturePosition();
  }, []);

  return (
    <Modal open={true} title="Capture GPS" onClose={onDone}>
      <div className={classes.wrapper}>
        {gpsQueryStatus.error && (
          <p>Unable to get GPS coordinates. Please allow access.</p>
        )}

        {gpsCrd && <GpsCard gpsCrd={gpsCrd} />}
        {gpsQueryStatus.pending && (
          <div className={classes.loadingIndicator}>
            <CircularProgress color="inherit" />
            <p>
              Please allow access if you get any prompt for location permission.
            </p>
          </div>
        )}
        {saveGpsStatus.pending && <LoadingIndicator />}
      </div>
      <div className={classes.buttonHolder}>
        <CustomButton
          variant="outlined-white"
          onClick={() => onDone && onDone()}
        >
          Cancel
        </CustomButton>
        {!(gpsQueryStatus.pending || saveGpsStatus.pending) && (
          <div>
            <SubmitButton onClick={capturePosition}>Refresh GPS</SubmitButton>

            {gpsCrd && (
              <SubmitButton
                onClick={() =>
                  saveGps({
                    device: {
                      latitude: gpsCrd.latitude,
                      longitude: gpsCrd.longitude,
                      accuracy: gpsCrd.accuracy,
                    },
                  })
                }
                style={{ marginLeft: "1em" }}
              >
                Save Coordinates
              </SubmitButton>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CaptureGPS;
